import { isNotEmpty, isNotValidDate, isNotValidTime } from "./validations";


export const validateFields = (data) => {
    const errors = {};

    //validate input fields
    if(!isNotEmpty(data.customerEmail)) {
        errors.email = "Email is required";
    }
    if(!isNotEmpty(data.customerName)) {
        errors.customerName = "Username is required";
    }
    if(!isNotEmpty(data.customerPhoneNumber)) {
        errors.customerPhoneNumber = "Phone number is required";
    }
    return errors;
}