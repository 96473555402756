import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { getTripModeId, getTripStatus } from '../../pages/utils/utils';
import { useNavigate } from 'react-router-dom';



// Function to render the label with active state
export function LabelForTabs({ children, isActive }) {
  return (
    <div className={`${isActive ? "text-primary-blue" : "text-placeholder-gray"} font-[420] text-[14px] `}>
      {children}
    </div>
  );
}

function TabNavigation({ tab, activeKey, setActiveKey, tabContents, getRides, setRowsPerPage, setPage, getFareManagementDetails, getEarningsDetails }) {

  const navigate = useNavigate()

  const onChange = (key) => {
    setActiveKey(key);
    if (tab === 'fareManagement') {
      getFareManagementDetails(getTripModeId(key))
      getEarningsDetails()
    }
    else if (tab === 'customerManagement') {
      return;
    }
    else if (tab === 'drivers') {
      return;
    }
    else if (tab === 'walletManagement') {
      return;
    }
    else if (tab === 'advertisement') {
      return;
    }

    else {
      getRides(getTripStatus(key))
      setRowsPerPage(25)
      setPage(0)
    }

  };


  return (
    <div className="w-full removeScrollBar">
      <Tabs style={{ fontFamily: "Poppins" }} items={tabContents} onChange={onChange} id='tabs' />
    </div>
  );
}

export default TabNavigation;
