export default function LiveTrackingPopoverContent({
    beginDate,
    beginTime
}) {
    return (
        <div className=" flex flex-col  ">
            <div className=" flex flex-col items-center hover:bg-gray-200 pl-2 rounded-md  pr-3 gap-1  py-1 ">
                
                <div style={{ fontSize: '12px', 'color': '#000' }}>{beginDate}</div>
                <div style={{ fontSize: '12px', 'color': 'blue' }}>{beginTime}</div>

            </div>

        </div>

    )
}