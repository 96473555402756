import { Link } from "react-router-dom";
import Table from "../../../reusable/Table/Table";
import TableCell from "../../../reusable/Table/TableCell";
import TableHead from "../../../reusable/Table/TableHead";
import CustomerFilters from "../components/CustomerFilters";
import StatusCard from "../../../reusable/Table/TripStatus";
import { useEffect, useState } from "react";
import { updateCustomer } from "../../../../services/CustomerManagementServices";
import Button from "../../../reusable/Button";
import { Modal, message } from "antd";

export default function ActiveUsersTable({
  customerData,
  filters,
  rowsPerPage,
  page,
  rowCount,
  handleChangePage,
  handleChangeRowsPerPage,
  setFilters,
  getAllCustomer,
  sortByColumn,
  setSortByColumn,
}) {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [blockComment, setBlockComment] = useState("");

  const activeCustomerTableColumns = [
    {
      id: "userID",
      name: <TableHead>Customer ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/customer-management/${row.userID}` }}
            state={row}
          >
            {row.userID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "userName",
      name: <TableHead>Name</TableHead>,
      cell: (row) => <TableCell>{row.userName ? row.userName : "-"}</TableCell>,
    },
    {
      id: "mobileNumber",
      name: <TableHead>Phone Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNumber}</TableCell>,
    },
    // {
    //   id: "customerEmail",
    //   name: <TableHead>Email</TableHead>,
    //   cell: (row) => <TableCell>{row.email ? row.email : "-"}</TableCell>,
    // },
    {
      id: "referralCode",
      name: <TableHead>Referral ID</TableHead>,
      cell: (row) => (
        <TableCell>{row.referralCode ? row.referralCode : "-"}</TableCell>
      ),
    },
    {
      id: "totalCountReferral",
      name: <TableHead width="180px">Total Friends Referred</TableHead>,
      cell: (row) => (
        <TableCell width="180px">
          {row.totalCountReferral ? row.totalCountReferral : "-"}
        </TableCell>
      ),
    },
    {
      id: "totalRewardPoint",
      name: <TableHead width="180px">Total Reward Points</TableHead>,
      cell: (row) => (
        <TableCell width="180px">
          {row.totalRewardPoint ? row.totalRewardPoint?.toFixed(2) : "-"}
        </TableCell>
      ),
    },
    {
      id: "totalReedmePoint",
      name: <TableHead width="180px">Total Redeemed Points</TableHead>,
      cell: (row) => (
        <TableCell width="180px">
          {row.totalReedmePoint ? row.totalReedmePoint?.toFixed(2) : "-"}
        </TableCell>
      ),
    },
    {
      id: "totalBalancePoint",
      name: <TableHead width="180px">Balance Reward Points</TableHead>,
      cell: (row) => (
        <TableCell width="180px">
          {row.totalBalancePoint ? row.totalBalancePoint?.toFixed(2) : "-"}
        </TableCell>
      ),
    },
    ,
    // {
    //   id: "customerType",
    //   name: <TableHead>Type</TableHead>,
    //   cell: (row) => (
    //     <TableCell>
    //       <StatusCard status={row.userCreatedBy === 3 ? "Manual" : row.userCreatedBy === 2 ? "Website" : "App"} />
    //     </TableCell>
    //   ),
    // },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>
          <Button
            bgColor={"#fff"}
            width={"auto"}
            height={"auto"}
            onClick={() => {
              setBlockModal(true);
              setSelectedRow(row);
            }}
            textColor={"#FF0000"}
            borderColor={"#FF0000"}
            text={"BLOCK"}
            disabled={row.id == 1} // Disable the button if row.id is 1
          />
        </TableCell>
      ),
    },
  ];

  async function handleBlockCustomer(row) {
    try {
      const response = await updateCustomer({
        id: +row.id,
        userStatus: 0,
        blockComment: blockComment,
      });
      message.success("Customer Blocked!");
      setBlockComment("");
      getAllCustomer(row.userStatus, page + 1, rowsPerPage);
    } catch (err) {
      message.error(err.response.data.message || err.message);
    }
  }

  return (
    <>
      <div className="w-full removeScrollBar pb-5">
        <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
          <CustomerFilters
            customerId={filters.customerId}
            customerTypeData={[
              { id: 1, customerType: "Application" },
              { id: 2, customerType: "Website" },
              { id: 3, customerType: "Manual" },
            ]}
            customerType={filters.customerType}
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            setFilters={setFilters}
          />
        </div>
        <Table
          columns={activeCustomerTableColumns}
          rows={customerData?.data}
          rowCount={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortByColumn={sortByColumn}
          setSortByColumn={setSortByColumn}
        />
        {/* 
      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>

          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => setConfirmationModal({ state: false, action: "" })}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action)
                  setConfirmationModal({ state: false, action: "" })
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{confirmationModal.action}</b></em> the ride ?</h1>
        </div>
      </Modal> */}

        <Modal
          title={
            <>
              <div className="flex gap-2 items-center">
                <svg
                  fill="#ff0000"
                  version="1.1"
                  id="Layer_1"
                  x="&amp;ns_extend;"
                  i="&amp;ns_ai;"
                  graph="&amp;ns_graphs;"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="25px"
                  height="25px"
                  viewBox="-2.4 -2.4 28.80 28.80"
                  enable-background="new 0 0 24 24"
                  space="preserve"
                  stroke="#ff0000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <metadata>
                      {" "}
                      <sfw xmlns="&amp;ns_sfw;">
                        {" "}
                        <slices> </slices>{" "}
                        <slicesourcebounds
                          width="505"
                          height="984"
                          bottomleftorigin="true"
                          x="0"
                          y="-984"
                        >
                          {" "}
                        </slicesourcebounds>{" "}
                      </sfw>{" "}
                    </metadata>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 S17.5,2,12,2z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M19.8,20.8c-0.3,0-0.5-0.1-0.7-0.3L3.5,4.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l15.6,15.6c0.4,0.4,0.4,1,0,1.4 C20.3,20.7,20,20.8,19.8,20.8z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
                <h1
                  className="text-lg"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Block Customer
                </h1>
              </div>
            </>
          }
          visible={blockModal}
          width={"500px"}
          footer={null}
          onCancel={() => setBlockModal(false)}
        >
          <div className="my-6 flex flex-col gap-1">
            <p>Comments</p>
            <textarea
              name="blockComment"
              rows="4"
              className="w-full border-border-gray border rounded-md p-2 focus:outline-none focus:border-primary-blue"
              placeholder="Enter comments for blocking"
              value={blockComment}
              onChange={(e) => setBlockComment(e.target.value)}
              maxLength={200}
            />
          </div>
          <div className="flex justify-center">
            <Button
              text={"Block"}
              textColor={"#fff"}
              width={"100px"}
              height={"32px"}
              fontSize={"12px"}
              onClick={() => {
                handleBlockCustomer(selectedRow);
                setBlockModal(false);
              }}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
