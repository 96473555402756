import { useState } from "react";
import Input from "../components/reusable/Input";
import { message } from "antd";
import Button from "../components/reusable/Button";
import MobileNumberInput from "../components/reusable/MobileNumberInput";
import logo from "../assets/cc_logo.png";
import axios from "axios";

export default function AccountDeletion() {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isRequestSent, setIsRequestSent] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    
    if (!email) {
      newErrors.email = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email";
    }
    
    if (!mobile) {
      newErrors.mobile = "Please enter your mobile number";
    } else if (mobile.replace(/\D/g, '').length !== 10) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");
    
    if (!validateFields()) {
      console.log("Validation failed", errors);
      return;
    }

    const data = {
      email: email.trim(),
      mobileNumber: parseInt(mobile.replace(/\D/g, ''), 10)
    };

    console.log("Sending request with data:", data);
    console.log("API URL:", `${process.env.REACT_APP_API_BASE_URL}/common/deletionRequest`);

    setLoading(true);

    try {
      console.log("Making axios request...");
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/common/deletionRequest`,
        data: data,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 5000,
      });

      console.log("Response received:", response);

      if (response?.data?.status === true) {
        setIsRequestSent(true);
        message.success(response.data.message || "Request has been sent!");
      } else {
        throw new Error(response.data?.message || "Invalid response format");
      }
    } catch (error) {
      console.error("Full error object:", error);
      
      if (error.response) {
        console.error("Error response:", {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers,
        });
        message.error(error.response.data?.message || "Server error occurred.");
      } else if (error.code === 'ECONNABORTED') {
        console.error("Request timed out");
        message.error("Request timed out. Please try again.");
      } else if (error.request) {
        console.error("No response received");
        message.error("Unable to reach server. Please check your connection.");
      } else {
        console.error("Error during request setup:", error.message);
        message.error("An error occurred while processing the request.");
      }
    } finally {
      setLoading(false);
    }
  };

  console.log("Current state:", { email, mobile, loading, errors, isRequestSent });

  return (
    <div className="min-h-screen flex items-center justify-center px-5 md:px-0">
      <div className={`flex flex-col justify-center items-center gap-2 ${
        isRequestSent ? "w-full" : "w-[360px]"
      } mb-12`}>
        <img src={logo} alt="logo" className="w-40" />
        <h1 className="text-4xl md:text-5xl font-semibold">Chennai Cabs</h1>
        {isRequestSent === false ? (
          <>
            <h2 className="text-xl font-medium">Account Deletion Request</h2>
            <form
              onSubmit={handleSubmit}
              className="space-y-7 flex flex-col items-center justify-center mt-6 shadow-md rounded-md border w-full px-8 py-12"
            >
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder="Please enter your email id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errors?.email}
                mandate="true"
              />
              <MobileNumberInput
                label="Mobile"
                name="mobile"
                placeholder="Please enter your mobile number"
                value={mobile}
                setValue={setMobile}
                error={errors?.mobile}
                mandate="true"
              />
              <div className="pt-4">
                <Button
                  type="submit"
                  loading={loading}
                  text="Submit"
                  color="primary"
                  width="170px"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center mt-6">
            <h2 className="text-xl font-medium text-center">
              Your account deletion request has been sent successfully!
            </h2>
            <p className="text-lg font-medium text-gray-500 mt-4">
              You can close this window.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}