import axios from 'axios';



export const getFareManagementList = async (tripMode) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ride/fareManagementList/?tripMode=${tripMode}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}

export const updateOccasionalFare = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ride/updateCarMode`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}

export const getEarningsDetail = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/earningFareMangemnet/findAll`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}

export const updateEarnings = async (id, payload) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/earningFareMangemnet/update?id=${id}`, { ...payload }, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        throw error;
    }
}