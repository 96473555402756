import { useEffect, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import TabNavigation from "../../components/reusable/TabNavigation";
import { LabelForTabs } from "../../components/reusable/TabNavigation";
import OneWayTrip from "./tabs/OneWayTrip";
import RoundTrip from "./tabs/RoundTrip";
import Rental from "./tabs/Rental";
import LocalDrop from "./tabs/LocalDrop";
import "./FareManagement.css"
import { getEarningsDetail, getFareManagementList } from "../../services/FareManagementServices";
import { message } from "antd";
import Earnings from "./tabs/Earnings";

export default function FareManagement() {

    useEffect(() => {
        const user = localStorage.getItem("CC_ADMIN_USERTYPE");

        if (user !== "superAdminPlus" && user !== "superAdmin") {
            alert("You are not authorized to access this page");
            window.location.href = "/";
        }
    }, []);

    const [activeKey, setActiveKey] = useState('one-way');
    const [fareManagementData, setFareManagementData] = useState([]);
    const [earningsData, setEarningsData] = useState([])
    const [loading, setLoading] = useState(false);

    const tabContents = [
        {
            key: 'one-way',
            label: (
                <LabelForTabs isActive={activeKey === 'one-way'}>
                    One Way Trip
                </LabelForTabs>
            ),
            children: <OneWayTrip activeKey={activeKey} fareManagementData={fareManagementData} loading={loading} getFareManagementDetails={getFareManagementDetails} />,
        },
        {
            key: 'round-way',
            label: (
                <LabelForTabs isActive={activeKey === 'round-way'}>
                    Round Trip
                </LabelForTabs>
            ),
            children: <RoundTrip activeKey={activeKey} fareManagementData={fareManagementData} loading={loading} getFareManagementDetails={getFareManagementDetails} />,
        },
        {
            key: 'rental',
            label: (
                <LabelForTabs isActive={activeKey === 'rental'}>
                    Rental
                </LabelForTabs>
            ),
            children: <Rental activeKey={activeKey} fareManagementData={fareManagementData} loading={loading} getFareManagementDetails={getFareManagementDetails} />,
        },
        {
            key: 'local-drop',
            label: (
                <LabelForTabs isActive={activeKey === 'local-drop'}>
                    Local Drop
                </LabelForTabs>
            ),
            children: <LocalDrop activeKey={activeKey} fareManagementData={fareManagementData} loading={loading} getFareManagementDetails={getFareManagementDetails} />,
        },
        {
            key: 'earnings',
            label: (
                <LabelForTabs isActive={activeKey === 'earnings'}>
                    Earnings
                </LabelForTabs>
            ),
            children: <Earnings activeKey={activeKey} earningsData={earningsData} getEarningsDetails={getEarningsDetails} />,
        },
    ];

    async function getFareManagementDetails(tripMode = 1) {
        setLoading(true)
        try {
            const response = await getFareManagementList(tripMode);
            setFareManagementData(response.data);
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            message.error(error.message || error.response.data.error || error.response.data.message)
        }
    }

    async function getEarningsDetails() {
        try {
            const response = await getEarningsDetail();
            setEarningsData(response.data);
        }
        catch (error) {
            message.error(error.message || error.response.data.error || error.response.data.message)
        }
    }

    useEffect(() => {
        getFareManagementDetails()
        getEarningsDetails()
    }, [])

    return (
        <>
            <PageLayout
                title={"Fare Management"}
                description={"Manage the fare details here"}
            >
                <div className="w-full flex md:mt-7">
                    <TabNavigation tab={'fareManagement'} activeKey={activeKey} setActiveKey={setActiveKey} tabContents={tabContents} getFareManagementDetails={getFareManagementDetails} getEarningsDetails={getEarningsDetails} />
                </div>
            </PageLayout>

        </>
    )
}