import { Navigate, Route, Routes } from "react-router-dom";

import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import OTPVerify from "./pages/auth/OTPVerify";
import ResetPassword from "./pages/auth/ResetPassword";

import NavbarLayout from "./components/layouts/NavbarLayout";
import BlankLayout from "./components/layouts/BlankLayout";

import Home from "./pages/home/Home";
import TripManagement from "./pages/trip-management/TripManagement";
import TripDetailsPage from "./pages/trip-management/TripDetailsPage";
import DriverManagement from "./pages/driver-management/DriverManagement";
import DriverDetailsPage from "./pages/driver-management/DriverDetailsPage";
import WalletManagement from "./pages/wallet-management/WalletManagement";
import FareManagement from "./pages/fare-management/FareManagement";
import CustomerManagement from "./pages/customer-management/CustomerManagement";
import CustomerDetails from "./pages/customer-management/CustomerDetails";
import RedemptionManagement from "./pages/redemption-management/RedemptionManagement";
import RedemptionDetailsPage from "./pages/redemption-management/RedemptionDetailsPage";
import AdminManagement from "./pages/admin-management/AdminManagement";

import AccountDeletion from "./pages/AccountDeletion";
import Advertisement from "./pages/advertisement/Advertisement";

//test new final test

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NavbarLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/trip-management" element={<TripManagement />} />
          <Route
            path="/trip-management/:id/:tripTypeParam"
            element={<TripDetailsPage />}
          />

          <Route path="/customer-management" element={<CustomerManagement />} />
          <Route
            path="/customer-management/:id"
            element={<CustomerDetails />}
          />

          <Route
            path="/driver-management"
            element={<Navigate to={"/driver-management/drivers"} />}
          />
          <Route
            path="/driver-management/:tab"
            element={<DriverManagement />}
          />
          <Route
            path="/driver-management/:tab/:id"
            element={<DriverDetailsPage />}
          />

          <Route path="/wallet-management" element={<WalletManagement />} />
          <Route path="/fare-management" element={<FareManagement />} />

          <Route
            path="/redemption-management"
            element={
              <Navigate to={"/redemption-management/pending-redemption"} />
            }
          />
          <Route
            path="/redemption-management/:tab"
            element={<RedemptionManagement />}
          />
          <Route
            path="/redemption-management/:tab/:id"
            element={<RedemptionDetailsPage />}
          />
          {/* <Route path="/driver-management/:tab/:id" element={<DriverDetailsPage />} /> */}

          <Route
            path="/admin-management"
            element={<Navigate to={"/admin-management/super-admin"} />}
          />
          <Route path="/admin-management/:tab" element={<AdminManagement />} />
          {/* <Route path="/redemption-management/:tab/:id" element={<RedemptionDetailsPage />} /> */}
          <Route path="/advertisement" element={<Advertisement />} />
        </Route>

        <Route path="/" element={<BlankLayout />}>
          <Route path="*" element={<Login />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<OTPVerify />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account-deletion" element={<AccountDeletion />} />
        </Route>
      </Routes>
    </>
  );
}
