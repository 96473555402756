import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import CommonContext from "../../../context-storage/CommonServicesHandler";
import { Modal, message } from "antd";
import Button from "../../reusable/Button";

export default function DriverTable({ activeKey,
  // page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  rowCount,
}) {
  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypeDropDown, setVehicleTypeDropDown] = useState([]);
  const [driverStatus, setDriverStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const { tripTypeData, setTripMode, vehicleTypeData, allVehicle } = useContext(CommonContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOk = async () => {
    if (rejectReason === "" && modalAction === "reject") {
      message.error("Please enter the rejection reason");
      return;
    }
    if (modalAction === "accept") {
      await acceptDriver(selectedRow);
    } else if (modalAction === "reject") {
      await rejectDriver(selectedRow, rejectReason);
    }
    setIsModalOpen(false);
    setRejectReason("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRejectReason("");
  };

  const openModal = (action, row) => {
    setModalAction(action);
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const acceptDriver = async (row) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${row.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        },
        body: JSON.stringify({
          driverVerify: 1,
        }),
      });
      // Handle success if needed
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const rejectDriver = async (row, reason) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${row.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        },
        body: JSON.stringify({
          driverVerify: 2,
          rejectReason: reason,
        }),
      });
      // Handle success if needed
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  useEffect(() => {
    setVehicleTypeDropDown(allVehicle);
  }, [vehicleTypeData]);


  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getDrivers?pageSize=${rowsPerPage}&currentPage=${page + 1}&driverVerify=0${search ? `&search=${search}` : ""}${vehicleType ? `&vehicleTypeID=${vehicleType.id}` : ""}`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );
        const data = await response.json();
        if (data.data) {

          setEmployees(data.data);
        }
        else {
          setEmployees([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error("Error:", error);
        }
        return error.response && error.response.data;
      }
    }; if (activeKey === 'pending') {
      fetchData();
    }
  }, [activeKey, vehicleType, search, page, rowsPerPage, driverStatus]);

  function makeActions(row, action) {
    alert(`data: ${JSON.stringify(row)} and the action is ${action}`);
  }


  const columns = [
    {
      id: "driverID",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={`/driver-management/${activeKey}/${row.driverID}?id=${row.id}`}
          >
            {row.driverID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell>{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNo",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType.name}</TableCell>,
    },
    {
      id: "mobileNo",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "driverType",
      name: <TableHead>Wallet</TableHead>,
      cell: (row) => <TableCell>₹ {row.walletAvailableBalace.toLocaleString('hi')} /-</TableCell>,
    },
    // {
    //   id: "action",
    //   name: <TableHead>Action</TableHead>,
    //   cell: (row) => (
    //     <TableCell>
    //       <div className="flex justify-center w-full gap-2 ">
    //         <button
    //           className="bg-[#FF0000] p-2 rounded"
    //           onClick={() => openModal("reject", row)}
    //         >
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="11"
    //             height="11"
    //             viewBox="0 0 14 14"
    //             fill="none"
    //           >
    //             <path
    //               d="M13 1L1 13M1 1L13 13"
    //               stroke="white"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //         </button>
    //         <button
    //           className="bg-[#175CD3]  rounded px-2 py-1"
    //           onClick={() => openModal("accept", row)}

    //         >
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="12"
    //             height="12"
    //             viewBox="0 0 18 13"
    //             fill="none"
    //           >
    //             <path
    //               d="M17 1L6 12L1 7"
    //               stroke="white"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //         </button>
    //       </div>
    //     </TableCell>
    //   ),
    // },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"text"}
          placeholder={"name/contact number/vehicle number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <DropDown
          label={"Vehicle Type"}
          placeholder={"Select vehicle type"}
          displayValue={"name"}
          data={vehicleTypeDropDown}
          value={vehicleType}
          setValue={setVehicleType}
          selectAll
        />
      </div>
      <Table columns={columns} rows={employees}
        page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage}
        rowCount={rowCount || 0}
      />
      <Modal
        title={<>
          <div className="flex gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
            <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
          </div>
        </>}
        open={isModalOpen}
        onCancel={handleCancel}
        okText={modalAction === "accept" ? "Accept" : "Reject"}
        cancelText="Cancel"
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() => handleCancel()}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {

                  handleOk();
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5  ">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>Are you sure want to <em><b>{modalAction === "accept" ? "Accept" : "Reject"}</b></em> Driver ?</h1>
        </div>
        {modalAction === "reject" && (
          <div className=" w-full flex  flex-col py-3">

            <Input
              placeholder="Enter rejection reason"
              label={"Reject Reason"}
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}