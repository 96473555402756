import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button";
import Input from "../../components/reusable/Input";
import LoginLayout from "../../components/layouts/login/LoginLayout";
import AuthServices from "../../services/AuthServices";
import AuthContext from "../../context-storage/AuthHandler";
import { message } from "antd";
import { validateFields } from "./validations/validate";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { setIsLoggedIn, setToken, setUserData } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const fields = {
      email,
      password,
    };

    const errors = validateFields(fields);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true);

      try {
        const response = await AuthServices.login(email, password);

        // Handle successful login (e.g., store token, navigate)
        if (response && response.status === true) {
          setLoading(false);
          message.success("Login successful!");
          window.localStorage.setItem("CC_ADMIN_TOKEN", response.accessToken)
          window.localStorage.setItem("CC_ADMIN_EMAIL", response.data.email);
          window.localStorage.setItem("CC_ADMIN_USERNAME", response.data.name);
          window.localStorage.setItem("CC_ADMIN_USERTYPE", response.data.userType);
          setUserData(response.data);
          // localStorage.setItem("userDetails", JSON.stringify({ email: response.data.email, name: response.data.name }))
          setToken(response.accessToken);
          setIsLoggedIn(true);
          navigate("/home");
        }
        if (response.status === false) {
          message.error(response.message);
          setLoading(false)
        }
      } catch (error) {
        setLoading(false);
        message.error(
          error.message ||
          error.response.data.message ||
          error.response.data.error
        );
      }
    }
  };

  function handleOnChange(field, value) {
    if (field === "email") {
      setEmail(value);
    }

    if (field === "password") {
      setPassword(value);
    }
  }

  useEffect(() => {
    const fields = { email };
    const emailErrors = validateFields(fields);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailErrors.email,
    }));
  }, [email]);

  // Validate password field
  useEffect(() => {
    const fields = { password };
    const passwordErrors = validateFields(fields);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordErrors.password,
    }));
  }, [password]);

  useEffect(() => {
    setErrors({});
  }, []);

  return (
    <LoginLayout title={"Hello Again !"} text={"Please enter your details"}>
      <form className="flex flex-col px-6  lg:px-0  gap-6 justify-center items-center   w-full lg:w-[400px]  ">
        {/* email input */}
        <Input
          label={"Email"}
          placeholder={"Enter your email address"}
          value={email}
          onChange={(e) => handleOnChange("email", e.target.value)}
          error={errors.email}
        />
        {/* password input */}
        <Input
          label={"Password"}
          placeholder={"Enter your password"}
          type={"password"}
          value={password}
          onChange={(e) => handleOnChange("password", e.target.value)}
          error={errors.password}
        />
        {/* remember-me input */}
        <div className="flex justify-between w-full my-1">
          <div className="flex gap-2 justify-center items-center">
            <input type="checkbox" />
            <p className="font-semibold text-text-black text-sm lg:text-base ">
              Remember me
            </p>
          </div>
          <Link
            to={"/forgot-password"}
            className="text-primary-blue font-semibold text-sm lg:text-base "
          >
            Forgot password?
          </Link>
        </div>
        {/* sign-in button */}
        <Button type={"submit"} loading={loading} text="Sign in" onClick={handleSignIn} />
      </form>
    </LoginLayout>
  );
}
