import { Card, Popover, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Input from '../../components/reusable/Input'
import PageLayout from "../../components/layouts/PageLayout";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Button from '../../components/reusable/Button';
import DateInput from "../../components/reusable/DateInput";
import TextArea from 'antd/es/input/TextArea';
import { useLocation, useParams } from 'react-router-dom';
import RejectAcceptModal from './components/RejectAcceptModal';
import { getRedeemOne } from '../../services/RedeemManagementServices';

export default function RedemptionDetailsPage() {

    const { tab, id } = useParams()
    const [showModal, setShowModal] = useState({
        show: false,
        type: ''
    })
    const [redemptionDetails, setRedemptionDetails] = useState({})
    const { state } = useLocation()

    const getRedeemDetails = async () => {
        try {
            const response = await getRedeemOne(state.id);
            console.log(response)
            if (response) {
                setRedemptionDetails(response.data)
            }
        }
        catch (error) {
            message.error(error.message || error.response.data.error || error.response.data.message)
        }
    }

    useEffect(() => {
        getRedeemDetails()
    }, [])

    return (
        <>
            <PageLayout
                title={"Redemption Management"}
                description={"View the customer redemption details here."}
            // buttonWidth={"120px"}
            // BreadcrumbsUrl={BreadcrumbsUrl}
            >
                {/* {
                    tab === 'pending-redemption' && (
                        <div className="absolute right-12 top-12">
                            <div className="w-full flex justify-center items-center gap-3 ">
                                <Button
                                    text={"Reject Request"}
                                    bgColor={"#fff"}
                                    height={"35px"}
                                    // onClick={() => {
                                    //     setIsEdit(false);
                                    //     setErrors({});
                                    // }}
                                    onClick={() => setShowModal({ show: true, type: 'reject' })}
                                    textColor={"#FF0000"}
                                    borderColor={"#FF0000"}
                                    fontSize={"12px"}
                                />
                                <Button
                                    text={"Complete Payment"}
                                    bgColor={"#1849A9"}
                                    height={"35px"}
                                    width={"150px"}
                                    // onClick={updateCustomerDetails}
                                    onClick={() => setShowModal({ show: true, type: 'complete' })}
                                    textColor={"#fff"}
                                    fontSize={"12px"}
                                />
                            </div>
                        </div>
                    )
                } */}

                <div style={{ height: 'calc(100vh - 200px)', overflowY: 'scroll' }} className="relative pb-10 md:pb-0">

                    {/* Customer Details card */}
                    <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                        <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                            <div className=" w-full text-start mt-1 text-gray-800 font-[700]" style={{ fontSize: '16px' }}>
                                Customer Details
                            </div>

                            <>
                                <div className=" w-full gap-5 h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">
                                    <Input
                                        label={"User Name"}
                                        placeholder={"Enter username"}
                                        value={redemptionDetails?.userName ? redemptionDetails?.userName : "-"}
                                        disabled
                                    // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                    // disabled={!isEdit ? true : false}
                                    // mandate={isEdit ? true : false}
                                    // error={errors.customerName}
                                    />
                                    <MobileNumberInput
                                        label={"Phone Number"}
                                        value={redemptionDetails?.customerMobileNumber ? redemptionDetails?.customerMobileNumber : "-"}
                                        disabled
                                    // value={customer?.customerPhoneNumber}
                                    // setValue={(item) => setCustomer(prev => ({ ...prev, customerPhoneNumber: item }))}
                                    // disabled
                                    />
                                    <Input
                                        label={"Email"}
                                        placeholder={"Enter Email"}
                                        value={redemptionDetails?.email ? redemptionDetails?.email : "-"}
                                        disabled
                                    // value={customer?.customerEmail ? customer?.customerEmail : null}
                                    // onChange={(e) => setCustomer(prev => ({ ...prev, customerEmail: e.target.value }))}
                                    // disabled={!isEdit ? true : false}
                                    // mandate={isEdit ? true : false}
                                    // error={errors.customerEmail}
                                    />

                                    {/* <div className="w-[300px]"></div> */}
                                </div>


                                {/* Update and Cancel buttons */}
                                {/* {
                                    isEdit &&
                                    <div className="flex justify-center items-center gap-3 mt-5">
                                        <Button
                                            text={"Cancel"}
                                            width={"100px"}
                                            bgColor={"#fff"}
                                            height={"35px"}
                                            onClick={() => {
                                                setIsEdit(false);
                                                setErrors({});
                                            }}
                                            textColor={"#1849A9"}
                                            borderColor={"#1849A9"}
                                            fontSize={"12px"}
                                        />
                                        <Button
                                            text={"Update"}
                                            width={"100px"}
                                            bgColor={"#1849A9"}
                                            height={"35px"}
                                            onClick={updateCustomerDetails}
                                            textColor={"#fff"}
                                            fontSize={"12px"}
                                            loading={loading}
                                        />
                                    </div>
                                } */}
                            </>
                        </Card>
                    </div>

                    {/* Redemption Details Card */}
                    <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                        <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                            <div className=" w-full text-start mt-1 text-gray-800 font-[700]" style={{ fontSize: '16px' }}>
                                Redemption Details
                            </div>
                            <>
                                <div className='fkex flex-col gap-2 mt-5'>
                                    <Input
                                        label={"Redemption ID"}
                                        placeholder={"Enter Redemption ID"}
                                        value={redemptionDetails?.redemptionID ? redemptionDetails?.redemptionID : "-"}
                                        // value={customer?.customerName ? customer?.customerName : null}
                                        // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                        // disabled={!isEdit ? true : false}
                                        // mandate={isEdit ? true : false}
                                        // error={errors.customerName}
                                        disabled
                                    />
                                    <div className=" w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">

                                        <Input
                                            label={"Redeem Amount"}
                                            placeholder={"Enter Redeem Amount"}
                                            value={redemptionDetails?.amount ? redemptionDetails?.amount : "-"}
                                            disabled
                                        // value={customer?.customerName ? customer?.customerName : null}
                                        // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                        // disabled={!isEdit ? true : false}
                                        // mandate={isEdit ? true : false}
                                        // error={errors.customerName}
                                        />
                                        <Input
                                            label={"Google Pay Profile Name"}
                                            placeholder={"Enter Google Pay Profile Name"}
                                            value={redemptionDetails?.gPayProfileName ? redemptionDetails.gPayProfileName : "-"}
                                            // setValue={(item) => setCustomer(prev => ({ ...prev, customerPhoneNumber: item }))}
                                            disabled
                                        />
                                        <Input
                                            label={"Google Pay Number"}
                                            placeholder={"Enter Google Pay Number"}
                                            value={redemptionDetails?.gPayNumber ? redemptionDetails?.gPayNumber : "-"}
                                            // placeholder={"Enter Email"}
                                            // value={customer?.customerEmail ? customer?.customerEmail : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerEmail: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerEmail}
                                            disabled
                                        />

                                        {/* <div className="w-[300px]"></div> */}
                                    </div>
                                </div>




                                {/* Update and Cancel buttons */}
                                {/* {
                                    isEditAddress &&
                                    <div className="flex justify-center items-center gap-3 mt-5">
                                        <Button
                                            text={"Cancel"}
                                            width={"100px"}
                                            bgColor={"#fff"}
                                            height={"35px"}
                                            onClick={() => {
                                                setIsEdit(false);
                                                setErrors({});
                                            }}
                                            textColor={"#1849A9"}
                                            borderColor={"#1849A9"}
                                            fontSize={"12px"}
                                        />
                                        <Button
                                            text={"Update"}
                                            width={"100px"}
                                            bgColor={"#1849A9"}
                                            height={"35px"}
                                            onClick={updateCustomerDetails}
                                            textColor={"#fff"}
                                            fontSize={"12px"}
                                            loading={loading}
                                        />
                                    </div>
                                } */}
                            </>
                        </Card>
                    </div>

                    {/* Redemption Payment Details Card */}
                    {
                        tab === 'completed-redemption' &&
                        < div className=" w-full px-1 flex  my-7 justify-center items-center ">
                            <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                                <div className=" w-full text-start mt-1 text-gray-800 font-[700]" style={{ fontSize: '16px' }}>
                                    Redemption Payment Details
                                </div>
                                <>
                                    <div className='fkex flex-col gap-2'>
                                        <div className=' w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3'>
                                            <Input
                                                label={"Redemption ID"}
                                                placeholder={"Enter Redemption ID"}
                                                value={redemptionDetails?.redemptionID ? redemptionDetails?.redemptionID : "-"}
                                                disabled
                                            // value={customer?.customerName ? customer?.customerName : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerName}
                                            />
                                            <DateInput
                                                label={"Payment Date"}
                                                placeholder={"Select Payment Date"}
                                                value={redemptionDetails?.paidAndRejectDate ? redemptionDetails?.paidAndRejectDate : "-"}
                                                disabled
                                            // placeholder={"Enter Redemption ID"}
                                            // value={customer?.customerName ? customer?.customerName : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerName}
                                            />
                                        </div>

                                        <div className=" w-full gap-5  h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">

                                            <Input
                                                label={"Redeem Amount"}
                                                placeholder={"Enter Redeem Amount"}
                                                value={redemptionDetails?.amount ? redemptionDetails?.amount : "-"}
                                                disabled
                                            // value={customer?.customerName ? customer?.customerName : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerName}
                                            />
                                            <Input
                                                label={"Payment Type"}
                                                placeholder={"Enter Payment Type"}
                                                value={redemptionDetails?.redemptiontType ? redemptionDetails?.redemptiontType : "-"}
                                                disabled
                                            // value={customer?.customerPhoneNumber}
                                            // setValue={(item) => setCustomer(prev => ({ ...prev, customerPhoneNumber: item }))}
                                            // disabled
                                            />
                                            <Input
                                                label={"Transaction ID"}
                                                placeholder={"Enter Transaction ID"}
                                                value={redemptionDetails?.transactionId ? redemptionDetails?.transactionId : "-"}
                                                disabled
                                            // placeholder={"Enter Email"}
                                            // value={customer?.customerEmail ? customer?.customerEmail : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerEmail: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerEmail}
                                            />

                                            {/* <div className="w-[300px]"></div> */}
                                        </div>
                                    </div>
                                </>
                            </Card>
                        </div>

                    }


                    {/* Reject Redemption Request */}
                    {
                        tab === 'rejected-redemption' && <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                            <Card className=" w-full flex flex-col gap-6 shadow-sm rounded-md">
                                <div className=" w-full text-start mt-1 text-gray-800 font-[700]" style={{ fontSize: '16px' }}>
                                    Reject Redemption Request
                                </div>
                                <>
                                    <div className='fkex flex-col gap-2 mt-5'>
                                        <TextArea
                                            autoSize={{ minRows: 5, maxRows: 9 }}
                                            label={"Redemption ID"}
                                            placeholder={"Enter Redemption ID"}
                                            value={redemptionDetails?.rejectReson ? redemptionDetails?.rejectReson : "-"}
                                            // value={customer?.customerName ? customer?.customerName : null}
                                            // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                            // disabled={!isEdit ? true : false}
                                            // mandate={isEdit ? true : false}
                                            // error={errors.customerName}
                                            disabled
                                        />
                                    </div>
                                </>
                            </Card>
                        </div>
                    }

                </div>
            </PageLayout>
            <RejectAcceptModal
                title={showModal.type === 'reject' ? 'Reject Redemption Request' : 'Complete Payment'}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            {/* <ConfirmModal
        isOpen={confirmModal}
        setIsOpen={setConfirmModal}
        title={"Delete ?"}
        description={"Do you want to delete this customer?"}
        button1={"No"}
        button2={"Yes"}
        onClickButton1={() => setConfirmModal(!confirmModal)}
        onClickButton2={() => {
            // setDeleteModal(!deleteModal);
            handleDelete();
        }}
    /> */}
            {/* <SuccessModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        animation="delete"
        title={"Customer deleted successfully."}
        onClose={() => navigate("/customer-management")}
    /> */}
        </>
    )
}
