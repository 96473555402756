import { Modal, Radio, message } from "antd";
import Button from "../../../reusable/Button";
import Input from "../../../reusable/Input";
import MobileNumberInput from "../../../reusable/MobileNumberInput";
import addTrip from "../../../../pages/trip-management/assets/add-trip.png";
import DropDownSelect from "../../../reusable/DropDownSelect";
import { useContext, useEffect, useState } from "react";
import DropDown from "../../../reusable/DropDown";
import CommonContext from "../../../../context-storage/CommonServicesHandler";
import { updateRide } from "../../../../services/TripManagementServices";
import { getDriversIdDropdown, getVehicleNumbers } from "../../../../services/DriverManagementServices";
import Table from "../../../reusable/Table/Table";
import TableHead from "../../../reusable/Table/TableHead";
import TableCell from "../../../reusable/Table/TableCell";
import searchIcon from "../../../../pages/trip-management/assets/search.png";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllDriversForDropdown } from "../../../../services/CommonServices";


export default function AssignDriverModal({
  isManualAllotedModal,
  setIsManualAllotedModal,
  selectedRow,
  tripStatus,
  getRides,
  rowsPerPage,
  page,
  isTripDetails
}) {

  const [loading, setLoading] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState(null);
  // const [mobileNumber, setMobileNumber] = useState(null);

  const [driverDetails, setDriverDetails] = useState([]);
  const [searchFilter, setSearchFilter] = useState("driverId")

  const [driverSearch, setDriverSearch] = useState("");
  const [vehicleSearch, setVehicleSearch] = useState("");
  // const [mobileSearch, setMobileSearch] = useState("");

  //useContext
  const { allDriver, getDriverOne, driverOne } = useContext(CommonContext);
  const [driverData, setDriverData] = useState([]);
  const [vehicleNos, setVehicleNos] = useState([]);
  // const [mobileNumberData, setMobileNumberData] = useState([]);

  const navigate = useNavigate();

  async function handleOnclick() {
    setLoading(true)
    try {
      const response = await updateRide(selectedRow.id, { tripStatus: 4, driverId: searchFilter === "driverId" ? +driverId.value : +vehicleNumber.value })
      setLoading(false);
      message.success('Ride manually alloted');
      navigate('/trip-management')
      handleSearchOnClear();
      setIsManualAllotedModal(false);
      if (!isTripDetails) {
        getRides(tripStatus, rowsPerPage, page + 1);
      }
    }
    catch (error) {
      setLoading(false);
      message.error(error.message || error.response.data.message || error.response.data.error)
    }
  }

  async function handleSearchClick() {
    try {
      const response = await getDriverOne(searchFilter === "driverId" ? driverId.value : vehicleNumber.value);
      setDriverDetails([response])
    } catch (error) {
      message.error(error.response?.data.message || error.response?.data.error || error.message);
    }
  }

  function handleSearchOnClear() {
    setDriverDetails([])
    setSearchFilter("driverId")
    setDriverId(null)
    setVehicleNumber(null);
  }

  async function getVehicleNumber(vehicleSearch) {

    try {
      const response = await getVehicleNumbers(vehicleSearch);
      if (response.data) {
        const formattedData = response.data.map(item => {
          return {
            value: item.id,
            label: item.vehicleNo
          }
        })
        setVehicleNos(formattedData)
      }
    }
    catch (err) {
      message.error(err.response.data.message || err.response.data.error || err.message);
    }
  }

  useEffect(() => {
    setSearchFilter("driverId")
    setDriverData(allDriver);
    getVehicleNumber();
  }, [])

  const columns = [
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.driverID}
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.driverName}
        </TableCell>
      ),
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType.name}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },
    {
      id: "vehicleNumber",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>
          <Button
            text={"Allot"}
            borderColor={"#1849A9"}
            bgColor={"#fff"}
            textColor={"#1849A9"}
            width={"50px"}
            height={"30px"}
            onClick={handleOnclick}
          />
        </TableCell>
      ),
    },
  ];

  function handleFilterChange(e) {
    setDriverId(null)
    setVehicleNumber(null)
    setDriverDetails([]);
    setSearchFilter(e.target.value)
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getAllDriversForDropdown(driverSearch);
      setDriverData(data.data);
    }
    fetchData();
    // }, [driverSearch])
  }, [])

  useEffect(() => {
    getVehicleNumber(vehicleSearch);
    // }, [vehicleSearch])
  }, [])

  // useEffect(() => {
  //   async function fetchData() {
  //     const data = await getAllDriversForDropdown(mobileSearch);
  //     setMobileNumberData(data.data);
  //   }
  //   fetchData();
  // }, [mobileSearch])

  useEffect(() => {
    if (driverId?.value || vehicleNumber?.value) {
      handleSearchClick()
    }
  }, [driverId, vehicleNumber])

  return (
    <Modal
      title={
        <>
          <img src={addTrip} />
        </>
      }
      visible={isManualAllotedModal}
      width={"1300px"}
      onCancel={() => { setIsManualAllotedModal(false); handleSearchOnClear(); }}
      // afterClose={handleSearchOnClear}
      footer={null}
    >
      <div className=" w-full  mt-6 mb-10  ">
        <div className=" w-full text-start text-base mt-1 font-semibold text-gray-800 ">
          Manual Allot
        </div>
        <div className=" w-full flex flex-col  gap-4 lg:gap-9  pt-10 ">
          <Radio.Group value={searchFilter} size="medium" onChange={handleFilterChange} className="pr-4 justify-end hidden md:flex">
            <Radio.Button value="driverId" style={{ fontSize: '12px' }}>Search by Driver ID</Radio.Button>
            <Radio.Button value="vehicleNumber" style={{ fontSize: '12px' }}>Search by Vehicle Number</Radio.Button>
            {/* <Radio.Button value="mobileNumber" style={{ fontSize: '12px' }}>Search by Mobile Number</Radio.Button> */}
          </Radio.Group>
          <Radio.Group value={searchFilter} size="medium" onChange={handleFilterChange} className="pr-4 justify-end md:hidden flex">
            <Radio.Button value="driverId" style={{ fontSize: '12px' }}>Driver ID</Radio.Button>
            <Radio.Button value="vehicleNumber" style={{ fontSize: '12px' }}>Vehicle Number</Radio.Button>
          </Radio.Group>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-end lg:justify-end items-center  gap-3 pr-4  ">

            {
              searchFilter === "driverId" ?
                // <DropDownSelect
                //   placeholder={"Select Driver ID"}
                //   displayValue={"driverID"}
                //   data={allDriver}
                //   value={driverId.driverID}
                //   setValue={setDriverId}
                //   onClear={handleSearchOnClear}
                // />
                <div className="flex flex-col md:flex-row md:items-center md:gap-3">
                  <p className="font-semibold text-slate-600">Select Driver : </p>
                  <Select
                    options={driverData}
                    onChange={(e) => setDriverId(e)}
                    value={driverId}
                    placeholder={"Select Driver ID"}
                    className="w-[250px]"
                    onInputChange={(e) => setDriverSearch(e)}
                  />
                </div> :
                // searchFilter === "vehicleNumber" ?
                // <DropDownSelect
                //   placeholder={"Select Vehicle Number"}
                //   displayValue={"vehicleNo"}
                //   data={vehicleNos}
                //   value={vehicleNumber.vehicleNo}
                //   setValue={setVehicleNumber}
                //   onClear={handleSearchOnClear}
                // />
                <div className="flex flex-col md:flex-row md:items-center md:gap-3">
                  <p className="font-semibold text-slate-600">Select Vehicle : </p>
                  <Select
                    options={vehicleNos}
                    onChange={(e) => setVehicleNumber(e)}
                    value={vehicleNumber}
                    placeholder={"Select Vehicle Number"}
                    className="w-[250px]"
                    onInputChange={(e) => setVehicleSearch(e)}
                  />
                </div>
              // :
              // <div className="flex flex-col md:flex-row md:items-center md:gap-3">
              //   <p className="font-semibold text-slate-600">Mobile Number : </p>
              //   <Select
              //     options={mobileNumberData}
              //     onChange={(e) => setMobileNumber(e)}
              //     value={mobileNumber}
              //     placeholder={"Select Mobile Number"}
              //     className="w-[250px]"
              //     onInputChange={(e) => setMobileSearch(e)}
              //   />
              // </div>
            }
            {/* <Button
              text={"Search"}
              borderColor={"#1849A9"}
              bgColor={"#1849A9"}
              textColor={"#fff"}
              width={"70px"}
              height={"40px"}
              className={" rounded-lg"}
              onClick={handleSearchClick}
            /> */}

          </div>
          <div>
            <Table
              columns={columns}
              rows={driverDetails}
              noNeedPagination={true}
            />
            {/* <Input
              label={"Driver Name"}
              placeholder={"Enter driver name"}
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
              disabled={true}
            />
            <MobileNumberInput
              label={"Mobile Number"}
              placeholder={"Enter mobile number"}
              value={mobileNumber}
              setValue={setMobileNumber}
              disabled={true}
            />
            <DropDown
              label={"Vehicle type"}
              placeholder={"Select vehicle type"}
              displayValue={"name"}
              data={allVehicle}
              value={vehicleType}
              setValue={setVehicleType}
              disabled={true}
            />

            <Input
              label={"Vehicle Name"}
              placeholder={"Enter vehicle name"}
              value={vehicleName}
              onChange={(e) => setVehicleName(e.target.value)}
              disabled={true}
            />
          </div>

          <div className=" w-full flex mt-4  justify-center items-center">
            <Button
              text={"Assign"}
              width={"100px"}
              height={"35px"}
              onClick={handleOnclick}
              loading={loading}
            /> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}
