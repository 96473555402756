function MultiSelectFilter({
  label,
  options,
  selectedOptions,
  updateSelectedOptions,
  displayValue,
  returnValue
}) {

  const toggleOption = (option) => {
    const currentIndex = selectedOptions?.indexOf(option[returnValue]);
    const newSelectedOptions = [...selectedOptions];
    if (currentIndex === -1) {
      newSelectedOptions?.push(option[returnValue]);
    } else {
      newSelectedOptions?.splice(currentIndex, 1);
    }
    updateSelectedOptions(newSelectedOptions);
  };

  return (
    <div className=" flex flex-col gap-2 px-2">

      {options?.map((option, index) => (
        <label className=" flex " key={index}>
          <input
            type="checkbox"
            checked={selectedOptions?.includes(option[returnValue])}
            onChange={() => toggleOption(option)}
          />
          <div className=" pl-2 font-semibold">

            {option[displayValue]}
          </div>
        </label>
      ))}
    </div>
  );
}

export default MultiSelectFilter;