import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DropDown from "../../reusable/DropDown";
import Input from "../../reusable/Input";
import CommonContext from "../../../context-storage/CommonServicesHandler";
import { Modal } from "antd";
import Button from "../../reusable/Button";
import DateUtils from "../../../services/DateUtils";

export default function ExpiredDrivers({ activeKey,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  rowCount,
}) {
  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [driverStatus, setDriverStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const [vehicleTypeDropDown, setVehicleTypeDropDown] = useState([]);
  const { tripTypeData, setTripMode, vehicleTypeData, allVehicle } = useContext(CommonContext);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [selectedRowForRevert, setSelectedRowForRevert] = useState(null);
  useEffect(() => {
    setVehicleTypeDropDown(allVehicle);
  }, [vehicleTypeData]);

  const driveStatusData =
    [
      {
        "id": "Part Time",
        "status": "Part Time"
      },
      {
        "id": "Dedicated",
        "status": "Dedicated"
      }
    ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/driver/getDrivers?pageSize=${rowsPerPage}&currentPage=${page + 1}&driverVerify=4&${search ? `&searchQuery=${search}` : ""}${vehicleType ? `&vehicleTypeID=${vehicleType.id}` : ""}`, {
          headers: {
            "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
            "email": localStorage.getItem("CC_ADMIN_EMAIL"),
            "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
          }
        }
        );
        const data = await response.json();
        if (data.data) {

          setEmployees(data.data);
        }
        else {
          setEmployees([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error("Error:", error);
        }
        return error.response && error.response.data;
      }
    };
    if (activeKey === 'expired') {
      fetchData();
    }

  }, [activeKey, vehicleType, search, driverStatus,
    page, rowsPerPage]);

  const revertDriver = async (row) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver/updateVerifyDriver/${row.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
          "email": localStorage.getItem("CC_ADMIN_EMAIL"),
          "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
        },
        body: JSON.stringify({
          driverVerify: 0,
        }),
      });
      // Handle success if needed
    } catch (error) {
      if (error.response && error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Error:", error);
      }
      return error.response && error.response.data;
    }
  };

  const columns = [
    {
      id: "driverId",
      name: <TableHead>Driver ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={`/driver-management/${activeKey}/${row.driverID}?id=${row.id}`}
          >
            {row.driverID}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "driverName",
      name: <TableHead>Driver Name</TableHead>,
      cell: (row) => <TableCell>{row.driverName}</TableCell>,
    },
    {
      id: "vehicleNumber",
      name: <TableHead>Vehicle Number</TableHead>,
      cell: (row) => <TableCell>{row.vehicleNo}</TableCell>,
    },
    {
      id: "vehicleName",
      name: <TableHead>Vehicle Name</TableHead>,
      cell: (row) => <TableCell>{row.vehicleName}</TableCell>,
    },

    {
      id: "vehicleType",
      name: <TableHead>Vehicle Type</TableHead>,
      cell: (row) => <TableCell>{row.vehicleType.name}</TableCell>,
    },
    {
      id: "mobileNumber",
      name: <TableHead>Mobile Number</TableHead>,
      cell: (row) => <TableCell>{row.mobileNo}</TableCell>,
    },
    {
      id: "expired",
      name: <TableHead>Expired Documents</TableHead>,
      cell: (row) => <TableCell>{row.manualExpDoc}</TableCell>
    },
    {
      id: "expiredDate",
      name: <TableHead>Expiry Date</TableHead>,
      cell: (row) => <TableCell>{row.manualExpDate ? DateUtils.convertDate(row.manualExpDate) : "-"}</TableCell>
    }

  ];

  const openRevertModal = (row) => {
    setSelectedRowForRevert(row);
    setIsRevertModalOpen(true);
  };

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-start items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Input
          label={"Search"}
          type={"text"}
          placeholder={"name/contact number/vehicle number"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <DropDown
          label={"Vehicle Type"}
          placeholder={"Select vehicle type"}
          displayValue={"name"}
          data={vehicleTypeDropDown}
          value={vehicleType}
          setValue={setVehicleType}
          selectAll
        />
        {/* <DropDown
          label={"Driver Status"}
          placeholder={"Select driver status"}
          displayValue={"id"}
          data={driveStatusData}
          value={driverStatus}
          setValue={setDriverStatus}
          mandate
        /> */}
      </div>
      <Table columns={columns} rows={employees} rowCount={rowCount || 0}
        page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage}
      />
      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="orange">
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              <h1 className="text-lg">Confirmation</h1>
            </div>
          </>
        }
        open={isRevertModalOpen}
        onCancel={() => {
          setIsRevertModalOpen(false);
        }}
        okText="Revert"
        cancelText="Cancel"
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"13px"}
                onClick={() => {
                  setIsRevertModalOpen(false);
                }}
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"13px"}
                onClick={async () => {
                  await revertDriver(selectedRowForRevert);
                  setIsRevertModalOpen(false);
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5">
          <h1 className="text-base">
            Are you sure you want to <em>
              <b>Revert Driver</b>
            </em>{" "}
            ?
          </h1>
        </div>
      </Modal>
    </div>
  );
}
