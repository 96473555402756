import React from 'react';
import Upload from "../../../reusable/Upload";
import Input from "../../../reusable/Input";
import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";
import MobileNumberInput from "../../../reusable/MobileNumberInput";
import Button from '../../../reusable/Button';


const AddAddressModal = ({
  tempStreetAddress,
  setTempStreetAddress,
  tempStreetAddressValidation,
  tempHouseNumber,
  setTempHouseNumber,
  tempHouseNumberValidation,
  tempState,
  setTempState,
  tempStateValidation,
  states,
  tempDistrict,
  setTempDistrict,
  tempDistrictValidation,
  districts,
  tempPincode,
  setTempPincode,
  tempPincodeValidation,
  isPermanentAddressSame,
  setIsPermanentAddressSame,
  permStreetAddress,
  setPermStreetAddress,
  permStreetAddressValidation,
  permHouseNumber,
  setPermHouseNumber,
  permHouseNumberValidation,
  permState,
  setPermState,
  permStateValidation,
  permDistrict,
  setPermDistrict,
  permDistrictValidation,
  permPincode,
  setPermPincode,
  permPincodeValidation,
  handleSubmit,
  setAddDriverModal2,
  fetchDriverData
}) => {
  return (
    <>
      <div className=" w-full  mt-6 mb-8  ">
        <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
          Edit Driver Details
        </div>
        <div className=" w-full flex flex-col  gap-3 lg:gap-6 py-10">
          {/* <p className="text-base font-medium underline">Temporary Address</p> */}
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
            <Input
              label={"Street Address"}
              placeholder={"Enter street address"}
              value={tempStreetAddress}
              error={tempStreetAddressValidation !== "" ? tempStreetAddressValidation : ""}
              onChange={(e) => setTempStreetAddress(e.target.value)}
            />
            <Input
              label={"House Number"}
              placeholder={"Enter house number"}
              value={tempHouseNumber}
              error={tempHouseNumberValidation !== "" ? tempHouseNumberValidation : ""}
              onChange={(e) => setTempHouseNumber(e.target.value)}
            />
            <DropDown
              label={"State"}
              placeholder={"Select state"}
              displayValue={"stateName"}
              data={states}
              value={tempState}
              error={tempStateValidation !== "" ? tempStateValidation : ""}
              setValue={setTempState}
            />
          </div>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
            <DropDown
              label={"District"}
              placeholder={"Select district"}
              displayValue={"districtName"}
              data={districts}
              value={tempDistrict}
              error={tempDistrictValidation !== "" ? tempDistrictValidation : ""}
              setValue={setTempDistrict}
            />

            <Input
              label={"Pincode"}
              type={"number"}
              placeholder={"Enter pincode"}
              value={tempPincode}
              error={tempPincodeValidation !== "" ? tempPincodeValidation : ""}
              onChange={(e) => setTempPincode(e.target.value)}
            />
            <div className=" w-full "></div>
          </div>
        </div>
        {/* <div className=" w-full flex flex-col  gap-3 lg:gap-6 pb-10">
          <div>
            <p className="text-base font-medium underline">Permanent Address</p>
            <div className="flex items-center pt-4">
              <input
                type="checkbox"
                id="permanentAddressSame"
                checked={isPermanentAddressSame}
                onChange={(e) => setIsPermanentAddressSame(e.target.checked)}
              />
              <label htmlFor="permanentAddressSame" className="ml-2 font-medium text-base">
                Is Permanent Address same as Temporary Address?
              </label>
            </div>
          </div>
          {!isPermanentAddressSame && (
            <>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
                <Input
                  label={"Street Address"}
                  placeholder={"Enter street address"}
                  value={permStreetAddress}
                  error={permStreetAddressValidation !== "" ? permStreetAddressValidation : ""}
                  onChange={(e) => setPermStreetAddress(e.target.value)}
                />
                <Input
                  label={"House Number"}
                  placeholder={"Enter house number"}
                  value={permHouseNumber}
                  error={permHouseNumberValidation !== "" ? permHouseNumberValidation : ""}
                  onChange={(e) => setPermHouseNumber(e.target.value)}
                />
                <DropDown
                  label={"State"}
                  placeholder={"Select state"}
                  displayValue={"stateName"}
                  data={states}
                  value={permState}
                  error={permStateValidation !== "" ? permStateValidation : ""}
                  setValue={setPermState}
                />
              </div>
              <div className=" w-full flex flex-wrap lg:flex-nowrap justify-start items-center gap-3 lg:gap-10 ">
                <DropDown
                  label={"District"}
                  placeholder={"Select district"}
                  displayValue={"districtName"}
                  data={districts}
                  value={permDistrict}
                  error={permDistrictValidation !== "" ? permDistrictValidation : ""}
                  setValue={setPermDistrict}
                />
                <Input
                  label={"Pincode"}
                  type={"number"}
                  placeholder={"Enter pincode"}
                  value={permPincode}
                  error={permPincodeValidation !== "" ? permPincodeValidation : ""}
                  onChange={(e) => setPermPincode(e.target.value)}
                />
                <div className=" w-full "></div>
              </div>
            </>
          )}
        </div> */}
        <div className=" w-full flex gap-6 justify-center items-center">
          <Button
            text={"Submit"}
            width={"170px"}
            onClick={() => {
              // Validate all fields before submitting
              let isValid = true;

              if (isValid) {
                handleSubmit(setAddDriverModal2);
                setAddDriverModal2(false);
                fetchDriverData();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddAddressModal;