import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import StatusCard from "../../reusable/Table/TripStatus";
import DateUtils from "../../../pages/utils/DateUtils";

export default function TripCashbaskEarningsTable({ rows, rowsPerPage, page }) {

    const columns = [
        {
            id: "tripId",
            name: <TableHead>Trip ID</TableHead>,
            cell: (row) => (
                <TableCell>
                    <Link
                        className=" text-primary-blue"
                        to={`/trip-management/${row?.ride?.rideId}/${row?.ride?.tripType === 1 ? 'One Way' : row?.ride?.tripType === 2 ? 'Round Way' : row?.ride.tripType === 3 ? 'Rental' : 'Local Drop'}`}
                        state={{ id: row?.ride?.id, tripMode: { id: row?.ride?.tripType } }}
                    >
                        {row?.ride?.rideId}
                    </Link>
                </TableCell>
            ),
        },
        {
            id: "tripDate",
            name: <TableHead>Trip Date</TableHead>,
            cell: (row) => <TableCell>{DateUtils.convertDate(row?.dateOfRide)}</TableCell>,
        },
        {
            id: "bookingMode",
            name: <TableHead>Trip Booking Mode</TableHead>,
            cell: (row) => <TableCell>
                <StatusCard status={row?.ride?.bookingModeId === 1 ? "Application" : row?.ride?.bookingModeId === 2 ? "Website" : "Manual"} />
            </TableCell>,
        },

        {
            id: "tripType",
            name: <TableHead>Trip Type</TableHead>,
            cell: (row) => <TableCell>{row?.ride?.tripType === 1 ? 'One Way' : row?.ride?.tripType === 2 ? 'Round Way' : row?.ride.tripType === 3 ? 'Rental' : 'Local Drop'}</TableCell>,
        },
        {
            id: "finalBaseFare",
            name: <TableHead>Final Base Fare</TableHead>,
            cell: (row) => <TableCell>₹ {row?.ride?.totalEndBaseFare?.toLocaleString('hi') || 0}</TableCell>,
        },
        {
            id: "pointsEarned",
            name: <TableHead>Points Earned</TableHead>,
            cell: (row) => <TableCell>₹ {row?.amount?.toFixed(2)?.toLocaleString('hi') || 0}</TableCell>,
        },
    ];

    return (
        <div className="w-full removeScrollBar pb-5">
            <Table columns={columns} rows={rows} rowsPerPage={rowsPerPage} page={page} rowCount={10} isRowsPerPage={true} />
        </div>
    );
}
