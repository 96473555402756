import login_logo from "../../../assets/login_logo.svg"

export default function RightSide({ title, text, description, children }) {
    return (
        <div className="  px-2   flex w-full h-full justify-center items-center">

            <div className={`flex  flex-col gap-6  lg:h-full h-fit lg:bg-transparent  bg-[#2f91fa0a] pt-10 lg:shadow-[0] shadow-lg rounded-md  w-full justify-center items-center ${description ? " pb-16 lg:pb-0 " : " pb-16 shadow-xl rounded-xl "}`}>

                <img src={login_logo} alt="" />
                <div className="flex flex-col justify-center items-center">
                    <h2 className=" text-lg lg:text-[2.5rem] text-[#101828] font-semibold ">{title}</h2>
                    <h3 className="text-text-black font-semibold text-sm lg:text-lg mt-1">{text}</h3>
                </div>
                <p className="font-medium text-text-black  w-full px-4 lg:px-0 lg:w-[24vw] text-center text-sm lg:text-lg ">{description}</p>
                {children}
            </div>
        </div>
    )
}
