import { Modal } from 'antd'
import React from 'react'
import DateInput from '../../../reusable/DateInput'
import DropDown from '../../../reusable/DropDown'
import Button from '../../../reusable/Button'
import Input from '../../../reusable/Input'
import addTrip from "../../../../assets/add-trip.png"
import TextArea from 'antd/es/input/TextArea'

export default function RedeemModal({ isRedeemModal, setIsRedeemModal, selectedRow, modalInputs, setModalInputs, handleSubmit, modalErrors, setModalErrors }) {
    return (
        <Modal
            title={
                <>
                    <div className="flex flex-col gap-5">
                        <img src={addTrip} width={40} alt="" />
                        <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>{isRedeemModal.action === 'accept' ? 'Complete Payment' : 'Reject Redemption Request'}</h1>
                    </div>

                </>
            }
            onCancel={() => {
                setIsRedeemModal({ action: '', state: false });
                setModalInputs({
                    paidDate: '',
                    redeemAmount: selectedRow?.amount,
                    redemptionType: '',
                    transactionId: '',
                    reason: ''
                })
                setModalErrors({
                    paidDateError: '',
                    redemptionTypeError: '',
                    transactionIdError: '',
                    reasonError: ''
                })
            }}
            visible={isRedeemModal.state}
            width={"1000px"}
            footer={() => (
                <>
                    <div className="flex gap-3 justify-center mb-10">
                        <Button
                            text={"Cancel"}
                            textColor={"#1849A9"}
                            width={"80px"}
                            height={"30px"}
                            bgColor={"#fff"}
                            borderColor={"#1849A9"}
                            fontSize={"12px"}
                            onClick={() => {
                                setIsRedeemModal({ action: '', state: false });
                                setModalInputs({
                                    paidDate: '',
                                    redeemAmount: selectedRow?.amount,
                                    redemptionType: '',
                                    transactionId: '',
                                    reason: ''
                                })
                                setModalErrors({
                                    paidDateError: '',
                                    redemptionTypeError: '',
                                    transactionIdError: '',
                                    reasonError: ''
                                })
                            }}
                        />
                        <Button
                            text={"Submit"}
                            textColor={"#fff"}
                            width={"80px"}
                            height={"30px"}
                            fontSize={"12px"}
                            onClick={handleSubmit}
                        />
                    </div>
                </>
            )}
        >
            <div className="w-full h-fit py-5">
                <h3 className="font-semibold">{isRedeemModal.action === 'accept' ? 'Redemption Payment Details' : <>Reason<span className='text-red-500'>*</span></>}</h3>
                {
                    isRedeemModal.action === 'accept' ? (
                        <>
                            <div className=" w-full flex-wrap gap-5  h-fit pb-5 flex justify-between items-center mt-5  ">
                                <Input
                                    label={"Redemption ID"}
                                    placeholder={"Enter redemption ID"}
                                    value={selectedRow?.redemptionID}
                                    disabled
                                />
                                <DateInput
                                    label={"Paid Date"}
                                    placeholder={"Enter Paid Date"}
                                    value={modalInputs?.paidDate}
                                    setValue={(item) => setModalInputs(prev => ({ ...prev, paidDate: item }))}
                                    error={modalErrors?.paidDateError}
                                    mandate
                                />
                                <Input
                                    invisible={true}
                                // value={customer.customerName}
                                // onChange={(e) => setCustomer(prev => ({ ...prev, customerName: e.target.value }))}
                                // error={errors.customerName}
                                />
                            </div>
                            <div className='w-full flex-wrap gap-5  h-fit pb-5 flex justify-between items-center'>
                                <Input
                                    label={"Redeem Amount"}
                                    placeholder={"Enter Redeem Amount"}
                                    // error={modalErrors?.redeemAmountError}
                                    // onChange={(e) => setModalInputs(prev => ({ ...prev, redeemAmount: e.target.value }))}
                                    value={selectedRow?.amount}
                                    disabled
                                    mandate
                                    amount
                                />
                                <DropDown
                                    label={"Redemption Type"}
                                    placeholder={"Enter Redemption Type"}
                                    data={[{ id: 1, type: 'Gpay' }, { id: 2, type: 'Phonepe' }, { id: 3, type: 'Paytm' }, { id: 4, type: 'A/C Transfer' }, { id: 5, type: 'Cash' }]}
                                    value={modalInputs?.redemptionType}
                                    setValue={(item) => setModalInputs(prev => ({ ...prev, redemptionType: item }))}
                                    displayValue={'type'}
                                    mandate
                                    error={modalErrors?.redemptionTypeError}
                                />
                                <Input
                                    label={"Transaction ID"}
                                    placeholder={"Enter Transaction ID"}
                                    // error={errors.email}
                                    onChange={(e) => setModalInputs(prev => ({ ...prev, transactionId: e.target.value }))}
                                    value={modalInputs?.transactionId}
                                    mandate
                                    error={modalErrors?.transactionIdError}
                                />
                            </div>
                        </>

                    ) : (
                        <>
                            <TextArea
                                autoSize={{ minRows: 5, maxRows: 9 }}
                                label={"Reason"}
                                placeholder={"Enter the description"}
                                value={modalInputs?.reason}
                                onChange={(e) => setModalInputs(prev => ({ ...prev, reason: e.target.value }))}
                            />
                            {
                                modalErrors?.reasonError &&
                                <div className="text-red-500 text-xs">{modalErrors?.reasonError}</div>
                            }
                        </>
                    )
                }

            </div>
        </Modal>
    )
}
