import React from 'react';
import Upload from "../../../reusable/Upload";
import Input from "../../../reusable/Input";
import DateInput from "../../../reusable/DateInput";
import DropDown from "../../../reusable/DropDown";
import MobileNumberInput from "../../../reusable/MobileNumberInput";

import driverImage from "../../../../assets/profileImage.png";
import Edit from '../../../../assets/edit_pencil.svg';
import Button from '../../../reusable/Button';

const AddDriverModal = ({
  addDriverModal,
  driverId,
  setAddDriverModal,
  routeId,
  setRouteId,
  routeValidation,
  setRouteValidation,
  driverName,
  setDriverName,
  driverNameValidation,
  setDriverNameValidation,
  mobileNumber,
  setMobileNumber,
  mobileNumberValidation,
  setMobileNumberValidation,
  vehicleName,
  setVehicleName,
  vehicleNumber,
  setVehicleNumber,
  vehicleType,
  setVehicleType,
  allVehicle,
  vehicleNameValidation,
  setVehicleNameValidation,
  vehicleNumberValidation,
  setVehicleNumberValidation,
  vehicleTypeValidation,
  setVehicleTypeValidation,
  profileImage,
  profileImageRef,
  handleProfileImage,
  handleSubmit,
}) => {
  return (
    <>
      <div className=" w-full  mt-6 mb-10  relative ">
        <button
          onClick={() => {
            setAddDriverModal(false);
            setRouteId(routeId);
            setRouteValidation("");
            setDriverName(driverName);
            setDriverNameValidation("");
            setMobileNumber(mobileNumber);
            setMobileNumberValidation("");
            setVehicleName(vehicleName);
            setVehicleNumber(vehicleNumber);
            setVehicleType(vehicleType);
          }}
          className="flex items-center absolute -right-3 -top-16 justify-center w-8 h-8 mb-4 mr-4  bg-gray-100  hover:bg-gray-200 rounded-full"
        >
          <div className="mb-1 text-xl  ml-[1px] text-center text-[#273775] h- w- ">
            x
          </div>
        </button>
        <div className=" w-full text-start text-lg mt-1 font-semibold text-gray-800 ">
          Edit Driver
        </div>
        <div className=" w-full flex flex-col  gap-3 lg:gap-3 py-8">
          {/* Profile Image */}
          <div className="flex flex-col items-center justify-center w-full">
            <div className="relative -top-8">
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="profile"
                  className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                />
              ) : (
                <img
                  src={driverImage}
                  alt="profile"
                  className="w-40 h-40 object-contain rounded-full"
                />
              )}
              <button
                className="absolute right-1 top-3"
                onClick={() => profileImageRef.current.click()}
              >
                <img src={Edit} alt="" />
                <input
                  type="file"
                  className="hidden"
                  ref={profileImageRef}
                  onChange={handleProfileImage}
                  accept="image/*"
                />
              </button>
            </div>
          </div>
          <p className="text-base font-semibold -mt-8">Personal Details</p>
          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10">
            <Input
              label={"Driver ID"}
              placeholder={"-"}
              value={driverId}
              error={routeValidation !== "" ? routeValidation : ""}
              onChange={(e) => setRouteId(e.target.value)}
              disabled={true}
            />
            <Input
              label={"Enter Driver Name"}
              placeholder={"Enter driver name"}
              value={driverName}
              error={driverNameValidation !== "" ? driverNameValidation : ""}
              onChange={(e) => setDriverName(e.target.value)}
              mandate={true}
            />
            <MobileNumberInput
              label={"Mobile Number"}
              placeholder={"Enter mobile number"}
              value={mobileNumber}
              error={mobileNumberValidation !== "" ? mobileNumberValidation : ""}
              setValue={setMobileNumber}
              mandate={true}
            />
          </div>
          <p className="text-base font-semibold pt-4">Vehicle Details</p>

          <div className=" w-full flex flex-wrap lg:flex-nowrap justify-center items-center gap-3 lg:gap-10 ">
            <Input
              label={"Vehicle Name"}
              placeholder={"Enter vehicle name"}
              value={vehicleName}
              error={vehicleNameValidation !== "" ? vehicleNameValidation : ""}
              onChange={(e) => setVehicleName(e.target.value)}
              mandate={true}
            />
            <Input
              label={"Vehicle Number"}
              upppercase={true}
              placeholder={"Enter vehicle number"}
              value={vehicleNumber}
              error={vehicleNumberValidation !== "" ? vehicleNumberValidation : ""}
              onChange={(e) => setVehicleNumber(e.target.value?.toUpperCase())}
              mandate={true}
            />
            <DropDown
              label={"Vehicle Type"}
              placeholder={"Select vehicle type"}
              displayValue={"name"}
              data={allVehicle}
              value={vehicleType}
              error={vehicleTypeValidation !== "" ? vehicleTypeValidation : ""}
              setValue={setVehicleType}
              mandate={true}
            />
          </div>
        </div>
        <div className=" w-full flex  justify-center items-center">
          <Button
            text={"Submit"}
            width={"170px"}
            onClick={() => {
              // Validate all fields before moving to the next modal
              let isValid = true;

              if (driverName === "") {
                setDriverNameValidation("Please enter driver name");
                isValid = false;
              } else {
                setDriverNameValidation("");
              }
              if (mobileNumber === "") {
                setMobileNumberValidation("Please enter mobile number");
                isValid = false;
              } else {
                setMobileNumberValidation("");
              }
              if (vehicleName === "") {
                setVehicleNameValidation("Please enter vehicle name");
                isValid = false;
              } else {
                setVehicleNameValidation("");
              }
              if (vehicleNumber === "") {
                setVehicleNumberValidation("Please enter vehicle number");
                isValid = false;
              } else {
                setVehicleNumberValidation("");
              }
              if (vehicleType === "") {
                setVehicleTypeValidation("Please select vehicle type");
                isValid = false;
              } else {
                setVehicleTypeValidation("");
              }
              if (isValid) {
                handleSubmit();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddDriverModal;