import VehicleFareUpdate from "../component/VehicleFareUpdate";
import sedan from "../assets/sedan.png";
import suv from "../assets/suv.png";
import suvPlus from "../assets/suvPlus.png";
import innova from "../assets/innova.png";
import tempo from "../assets/tempo.png"
import { useEffect, useState } from "react";


export default function RoundTrip({
    getFareManagementDetails,
    fareManagementData,
    loading,
    activeKey
}) {

    const [isEditDefault, setIsEditDefault] = useState({
        sedan: false,
        suv: false,
    })
    const [isEditOccasional, setIsEditOccasional] = useState({
        sedan: false,
        suv: false,
    })
    const [isEditActive, setIsEditActive] = useState(false)

    return (
        <>
            <div className="flex flex-col gap-8 w-full justify-center md:p-4 md:px-9 md:mb-12 md:pb-0">
                {
                    fareManagementData.map(vehicle => (
                        <VehicleFareUpdate
                            key={vehicle.id}
                            id={vehicle.id}
                            loading={loading}
                            vehicleType={vehicle.carType}
                            vehicalImg={vehicle.id === "4" ? sedan : vehicle.id === "5" ? suv : vehicle.id === "6" ? suvPlus : vehicle.id === "7" ? tempo : innova}
                            numberOfPersons={vehicle.noOfPerson}
                            fromDateFromApi={vehicle.fromDate}
                            toDateFromApi={vehicle.toDate}
                            farePerKm={vehicle.farePerKm}
                            occasionalFare={vehicle.occasionalFare}
                            occasionalDriverFare={vehicle.occasionalDriverFare}
                            driverFeeAndFarePerHour={vehicle.driverFeeAndFarePerHour}
                            acOrNonAc={"AC"}
                            getFareManagementDetails={getFareManagementDetails}
                            activeKey={activeKey}
                            isEditDefault={isEditDefault}
                            setIsEditDefault={setIsEditDefault}
                            isEditOccasional={isEditOccasional}
                            setIsEditOccasional={setIsEditOccasional}
                            isEditActive={isEditActive}
                            setIsEditActive={setIsEditActive}
                        />
                    ))
                }
            </div>
        </>
    )
}