import { useEffect, useState } from "react";
import DateInput from "../../reusable/DateInput";
import DateUtils from "../../../services/DateUtils";
import { getSummary } from "../../../services/WalletServices";
import { message } from "antd";

export default function SummaryWallet() {

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [summary, setSummary] = useState([]);

    const fetchSummary = async () => {
        try {
            const data = await getSummary(fromDate, toDate);
            setSummary(data.data);
        }
        catch (error) {
            message.error(error.response.data.message || error.response.data.error || error.message)
        }
    };

    useEffect(() => {
        setFromDate(DateUtils.getTMinusDate(7))
        setToDate(DateUtils.getCurrentDate())
    }, [])

    useEffect(() => {
        if (fromDate?.length && toDate?.length) {
            fetchSummary();
        }
    }, [fromDate, toDate])

    const data = [
        {
            label: "Total Credited",
            amount: summary?.totalCredited || 0
        },
        {
            label: "Total Debited",
            amount: summary?.totalDebedited || 0
        },
        {
            label: "Total Balance",
            amount: summary?.totalWalletBalance || 0
        }
    ];

    const getBackgroundColor = (index) => {
        if (index === 0) {
            return "bg-[#00904D]"
        }
        if (index === 1) {
            return "bg-[#B42318]"
        }
        if (index === 2) {
            return "bg-[#1849A9]"
        }
    };

    return (
        <div className="w-full removeScrollBar pb-5">
            <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap lg:justify-start items-center mb-5 mt-2">
                <DateInput
                    label={"From date"}
                    placeholder={"Select from date"}
                    value={fromDate}
                    setValue={setFromDate}
                    isFilter
                />
                <DateInput
                    label={"To date"}
                    placeholder={"Select to date"}
                    value={toDate}
                    setValue={setToDate}
                    isFilter
                />
            </div>
            <div className="flex items-center gap-5 justify-center flex-wrap my-10">
                {
                    data.map((item, index) => (
                        <div key={index} className={`flex flex-col gap-3 justify-center items-center w-[315px] h-[160px]  rounded-[8px] ${getBackgroundColor(index)}`}>
                            <p className="text-[18px] text-[#FFFFFF] font-[600]">{item.label}</p>
                            <h1 className="text-[24px] text-[#FFFFFF] font-[600]">₹ {item.amount?.toLocaleString('hi')} /-</h1>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}