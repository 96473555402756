import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { Typography } from "antd";
import PageLayout from "../../components/layouts/PageLayout";
import TabNavigation, { LabelForTabs } from "../../components/reusable/TabNavigation";
import { useState } from "react";

export default function Advertisement() {

    const [activeKey, setActiveKey] = useState("website");

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    };

    // const BreadcrumbsUrl = () => (
    //     <>
    //         <Breadcrumbs
    //             separator={<NavigateNextIcon fontSize="small" />}
    //             aria-label="breadcrumb"
    //         >
    //             <Link color="inherit" href="/" onClick={handleClick}>
    //                 Trip Management
    //             </Link>
    //             <Link
    //                 color="inherit"
    //                 href="/getting-started/installation/"
    //                 onClick={handleClick}
    //             >
    //                 Not Allotted
    //             </Link>
    //             <Typography color="text.primary">LC007</Typography>
    //         </Breadcrumbs>
    //     </>
    // );

    const tabContents = [
        {
            key: "website",
            label: (
                <LabelForTabs isActive={activeKey === "website"}>Website</LabelForTabs>
            ),
            children: <></>
        },
        {
            key: "customer",
            label: (
                <LabelForTabs isActive={activeKey === "customer"}>Customer</LabelForTabs>
            ),
            children: <></>
        },
        {
            key: "driver",
            label: (
                <LabelForTabs isActive={activeKey === "driver"}>Driver</LabelForTabs>
            ),
            children: <></>
        },
    ];

    return (
        <>
            <PageLayout
                title={"Advertisement"}
                description={"Manage the advertisement details here"}
            // BreadcrumbsUrl={BreadcrumbsUrl}
            >
                <div className=" w-full flex md:mt-7">
                    <TabNavigation
                        tab={"advertisement"}
                        tabContents={tabContents}
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                    />
                </div>
            </PageLayout>
        </>
    );
}
