import { BarChart } from '@mui/x-charts/BarChart';
import filter from "../../../assets/filter.svg";
import FilterDates from '../../../components/reusable/FilterDates';
import FilterLayout from '../../../components/layouts/FilterLayout';
import { useState } from 'react';
import { message } from 'antd';

export default function TripTypeSummary({ tripTypeSummaryData, filterDate, setFilterDate }) {

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const handleClearFilter = () => {
        message.success("cleared filters of Trip Summary Total");
        setFilterDate({
            firstDate: "",
            secondDate: "",
        })
    };

    const filterContent = [
        {
            title: "Date",
            body: (
                <div>
                    {" "}
                    <FilterDates
                        firstDateLabel={"Start Date"}
                        secondDateLabel={"End Date"}
                        date={filterDate}
                        setDate={setFilterDate}
                        isToDate={false}
                        mandate
                    />{" "}
                </div>
            ),
        },
    ];
    const xLabels = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun'
    ];

    return (
        <>
            <div className=' shadow-sm pl-4 py-4 w-fit  hidden lg:flex flex-col border rounded-md relative'>
                <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
                    <FilterLayout
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        content={filterContent}
                        handleClearFilter={handleClearFilter}
                    />
                </div>
                <p className="text-lg font-semibold w-fit">Trip Type Summary</p>
                <BarChart
                    // width={400}
                    height={300}
                    series={[
                        { data: tripTypeSummaryData?.oneWay, label: 'One Way', id: 'oneway', color: '#B51658' },
                        { data: tripTypeSummaryData?.roundWay, label: 'Round Way', id: 'roundway', color: '#18C888' },
                        { data: tripTypeSummaryData?.rental, label: 'Rental', id: 'rental', color: '#FDBB1E' },
                        { data: tripTypeSummaryData?.localDrop, label: 'Local Drop', id: 'localDrop', color: '#B456A4' },
                    ]}
                    xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                />
                <div className="flex gap-4 pr-4 justify-center items-start font-semibold">
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#B51658]" />
                        <p>One Way</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#18C888]" />
                        <p>Round Way</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#FDBB1E]" />
                        <p>Rental</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#B456A4]" />
                        <p>Local Drop</p>
                    </div>
                </div>
            </div>
            <div className='shadow-lg pl-4 py-4 w-fit  flex lg:hidden flex-col border rounded-md relative'>
                <div className="absolute m-4 border border-border-gray rounded-lg top-0 right-0">
                    <FilterLayout
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                        content={filterContent}
                        handleClearFilter={handleClearFilter}
                    />
                </div>
                <p className="text-lg font-semibold w-fit">Trip Type Summary</p>
                <BarChart
                    // width={400}
                    height={300}
                    series={[
                        { data: tripTypeSummaryData?.oneWay, label: 'One Way', id: 'oneway', color: '#B51658' },
                        { data: tripTypeSummaryData?.roundWay, label: 'Round Way', id: 'roundway', color: '#18C888' },
                        { data: tripTypeSummaryData?.rental, label: 'Rental', id: 'rental', color: '#FDBB1E' },
                        { data: tripTypeSummaryData?.localDrop, label: 'Local Drop', id: 'localDrop', color: '#B456A4' },
                    ]}
                    xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                />
                <div className="flex gap-4 pr-4 justify-center items-start font-semibold">
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#B51658]" />
                        <p>One Way</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#18C888]" />
                        <p>Round Way</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#FDBB1E]" />
                        <p>Rental</p>
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        <div className="w-3 h-3 rounded-full bg-[#B456A4]" />
                        <p>Local Drop</p>
                    </div>
                </div>
            </div>
        </>
    );
}
