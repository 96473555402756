import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/layouts/PageLayout'
import { Modal, Tabs, message } from 'antd'
import { LabelForTabs } from '../../components/reusable/TabNavigation';
import AdminTable from '../../components/tables/admin-management/AdminTable';
import { useNavigate } from 'react-router-dom';
import { CreateAdmin, getAllAdminData } from '../../services/AdminServices';
import MobileNumberInput from '../../components/reusable/MobileNumberInput';
import DateInput from '../../components/reusable/DateInput';
import Button from '../../components/reusable/Button';
import Input from '../../components/reusable/Input';
import DateUtils from '../utils/DateUtils';
const { TabPane } = Tabs;

export default function AdminManagement() {

    const navigate = useNavigate()

    const [fetchNeeded, setFetchNeeded] = useState(false);
    const [activeKey, setActiveKey] = useState('super-admin');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [adminData, setAdminData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [date, setDate] = useState(DateUtils.getCurrentDateFormat());

    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [dateError, setDateError] = useState("");

    useEffect(() => {
        const user = localStorage.getItem("CC_ADMIN_USERTYPE");

        if (user !== "superAdminPlus" && user !== "superAdmin") {
            alert("You are not authorized to access this page");
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        allAdminData(getUserType(), page, rowsPerPage);
    }, [activeKey, fetchNeeded, page, rowsPerPage]);

    const getUserType = () => {
        if (activeKey === 'super-admin') {
            return 'superAdmin';
        }
        else if (activeKey === 'admin') {
            return 'admin';
        }
        else if (activeKey === 'supervisor') {
            return 'supervisor';
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isValid = () => {
        let valid = true;
        if (name === "") {
            setNameError("Name is required");
            valid = false;
        } else {
            setNameError("");
        }
        if (email === "") {
            setEmailError("Email is required");
            valid = false;
        } else {
            setEmailError("");
        }
        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        } else {
            setPasswordError("");
        }
        if (mobileNumber === "") {
            setMobileNumberError("Mobile number is required");
            valid = false;
        } else {
            setMobileNumberError("");
        }
        if (date === "") {
            setDateError("Date is required");
            valid = false;
        } else {
            setDateError("");
        }
        return valid;
    };

    const allAdminData = async (userType, page, rowsPerPage, searchQuery) => {
        try {
            const response = await getAllAdminData(userType, page + 1, rowsPerPage);
            if (response.status) {
                setAdminData(response.data);
                // setTotalCount(response.data.referalCount);
            }
        }
        catch (err) {
            message.error(err.response.data.message || err.response.data.error || err.message);
        }

    }

    const handleCreateAdmin = async () => {
        if (!isValid()) {
            return;
        }
        const data = {
            name: name,
            email: email,
            password: password,
            mobileNumber: parseInt(mobileNumber.split(" ").join("")),
            date: DateUtils.convertDate(date),
            createdBy: 1,
            userType: getUserType(),
        }
        try {
            const response = await CreateAdmin(data);
            if (response.status) {
                message.success("User added successfully");
                setName("");
                setEmail("");
                setPassword("");
                setMobileNumber("");
                setShowModal(false);
                setFetchNeeded(!fetchNeeded);
            } else {
                message.error(response.message);
            }
        }
        catch (error) {
            message.error(error.response.data.message || error.response.data.error || error.message)
        }
    };

    const tabContents = [
        {
            key: "super-admin",
            label: (
                <LabelForTabs isActive={activeKey === "super-admin"}>
                    Super Admin
                    {/* ({count?.getPendingRedeemCount}) */}
                </LabelForTabs>
            ),
            children: (
                <AdminTable
                    adminData={adminData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={10}
                    activeKey={activeKey}
                    fetchNeeded={fetchNeeded}
                    setFetchNeeded={setFetchNeeded}

                />
            ),
        },
        {
            key: "admin",
            label: (
                <LabelForTabs isActive={activeKey === "admin"}>
                    Admin
                    {/* ({count?.getApprovedRedeemCount}) */}
                </LabelForTabs>
            ),
            children: (
                <AdminTable
                    adminData={adminData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={10}
                    activeKey={activeKey}
                    fetchNeeded={fetchNeeded}
                    setFetchNeeded={setFetchNeeded}
                />
            ),
        },
        {
            key: "supervisor",
            label: (
                <LabelForTabs isActive={activeKey === "supervisor"}>
                    Supervisor
                    {/* ({count?.getRejectedRedeemCount}) */}
                </LabelForTabs>
            ),
            children: (
                <AdminTable
                    adminData={adminData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={10}
                    activeKey={activeKey}
                    fetchNeeded={fetchNeeded}
                    setFetchNeeded={setFetchNeeded}
                />
            ),
        },
    ];

    const onChange = (key) => {
        setActiveKey(key)
        navigate(`/admin-management/${key}`);
        setPage(0);
        setRowsPerPage(25);
    }

    return (
        <>
            <PageLayout
                title={"Admin Management"}
                description={"View Admin details here"}
                buttonTitle={`Add ${activeKey.charAt(0).toUpperCase() + activeKey.slice(1)}`}
                setAddTripModal={setShowModal}
            >
                <div className=" w-full flex mt-7">
                    <Tabs
                        style={{
                            width: "100%",
                            height: "100%",
                            overflowY: "scroll",
                        }}
                        className=""
                        activeKey={activeKey}
                        defaultActiveKey="customer-referral"
                        onChange={onChange}
                    >
                        {tabContents.map((tab) => (
                            <TabPane
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowY: "scroll",
                                }}
                                tab={tab.label}
                                key={tab.key}
                            >
                                {tab.children}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
                <Modal
                    title={
                        <div className="flex flex-col gap-2">
                            <p>Add {activeKey.charAt(0).toUpperCase() + activeKey.slice(1)}</p>
                        </div>
                    }
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    footer={null}
                    width={700}
                    centered
                >
                    <div className=" w-full flex-col gap-10 mt-10  flex  justify-center items-center">
                        <div className=" gap-10 items-center flex-wrap flex w-full">
                            <Input
                                label="Name"
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={nameError}
                                type="text"
                                mandate
                            />
                            <Input
                                label="Email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={emailError}
                                type="text"
                                mandate
                            />
                        </div>
                        <div className="flex gap-10 items-center flex-wrap w-full">
                            <Input
                                label="Password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={passwordError}
                                type="text"
                                mandate
                            />
                            <MobileNumberInput
                                label="Mobile Number"
                                placeholder="Enter Mobile Number"
                                value={mobileNumber}
                                setValue={setMobileNumber}
                                error={mobileNumberError}
                                mandate
                            />
                        </div>
                        <div className="flex gap-10 items-center justify-start flex-wrap w-full">
                            <DateInput
                                label="Date"
                                placeholder="Select date"
                                value={date}
                                setValue={setDate}
                                error={dateError}
                                disabled
                            />
                        </div>
                        <div className="pb-2">
                            <Button
                                text="Create User"
                                width="130%"
                                borderColor="#1849A9"
                                type="button"
                                onClick={handleCreateAdmin}
                            />
                        </div>
                    </div>
                </Modal>
            </PageLayout>
        </>
    )
}
