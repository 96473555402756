import { useContext, useEffect, useState } from 'react';
import TicketSummary from './charts/TicketSummary';
import TotalTripSummary from './charts/TotalTripSummary';
import TripSummary from './charts/TripSummary';
import TicketDetails from './charts/TicketDetails';
import { getDriverSummary, getTripSummary, getTripSummaryTotal, getTripTypeSummary, getVehicleTypeSummary } from '../../services/HomeServices';
import DateUtils from '../../services/DateUtils';
import CommonContext from '../../context-storage/CommonServicesHandler';
import { message } from 'antd';
import DriverManagementSummary from './charts/DriverManagementSummary';
import VehicleTypeSummary from './charts/VehicleTypeSummary';
import TripTypeSummary from './charts/TripTypeSummary';

export default function Home() {
    const { allVehicle, tripTypeData } = useContext(CommonContext);

    const [userName, setUserName] = useState("");
    const [tripData, setTripData] = useState([]);
    const [totalTripData, setTotalTripData] = useState([]);

    const [filtersTripSummary, setFiltersTripSummary] = useState({
        tripType: [],
        vehicleType: [],
    });
    const [filterDateTripSummary, setFilterDateTripSummary] = useState({
        firstDate: DateUtils.getTMinusDate(7),
        secondDate: DateUtils.getCurrentDate(),
    });

    const [filtersTotalTripSummary, setFiltersTotalTripSummary] = useState({
        tripType: [],
        vehicleType: [],
    });
    const [filterDateTotalTripSummary, setFilterDateTotalTripSummary] = useState({
        firstDate: DateUtils.getTMinusDate(7),
        secondDate: DateUtils.getCurrentDate(),
    });

    const [tripTypeSummary, setTripTypeSummary] = useState({
        oneWay: [0, 0, 0, 0, 0, 0, 0],
        roundWay: [0, 0, 0, 0, 0, 0, 0],
        rental: [0, 0, 0, 0, 0, 0, 0],
        localDrop: [0, 0, 0, 0, 0, 0, 0]
    });
    const [filterDateTripTypeSummary, setFilterDateTripTypeSummary] = useState({
        firstDate: ""
    });
    const [vehicleTypeSummary, setVehicleTypeSummary] = useState();
    const [driverSummary, setDriverSummary] = useState();
    const [filterDateDriverSummary, setFilterDateDriverSummary] = useState({
        firstDate: "",
        secondDate: "",
    });
    const [filterDateVehicleTypeSummary, setFilterDateVehicleTypeSummary] = useState({
        firstDate: "",
        secondDate: "",
    });

    useEffect(() => {
        setUserName("Shine");
    }, []);

    useEffect(() => {
        let bodyData = {
            tripType: filtersTripSummary.tripType.length ? filtersTripSummary.tripType : "",
            carType: filtersTripSummary.vehicleType.length ? filtersTripSummary.vehicleType : "",
            fromDate: filterDateTripSummary.firstDate,
            toDate: filterDateTripSummary.secondDate,
        };
        const fetchTripSummary = async () => {
            try {
                const data = await getTripSummary(bodyData);
                setTripData(data);
            }
            catch (error) {
                message.error(error.response.data.message || error.response.data.error || error.message)
            }
        }
        fetchTripSummary();
    }, [filtersTripSummary, filterDateTripSummary]);

    useEffect(() => {
        let bodyData = {
            tripType: filtersTotalTripSummary.tripType.length ? filtersTotalTripSummary.tripType : "",
            carType: filtersTotalTripSummary.vehicleType.length ? filtersTotalTripSummary.vehicleType : "",
            fromDate: filterDateTotalTripSummary.firstDate,
            toDate: filterDateTotalTripSummary.secondDate,
        };
        const fetchTripSummaryTotal = async () => {
            try {
                const data = await getTripSummaryTotal(bodyData);
                setTotalTripData(data);
            }
            catch (error) {
                message.error(error.response.data.message || error.response.data.error || error.message)
            }
        }
        fetchTripSummaryTotal();
    }, [filtersTotalTripSummary, filterDateTotalTripSummary]);

    useEffect(() => {

        const getVehicleTypeSum = async () => {
            const filterData = {
                fromDate: filterDateVehicleTypeSummary?.firstDate,
                toDate: filterDateVehicleTypeSummary?.secondDate,
            }
            try {
                const response = await getVehicleTypeSummary(filterData);
                if (response) {
                    setVehicleTypeSummary(response);
                }
            } catch (error) {
                message.error(error.message || error.response.data.message || error.response.data.error)
            }
        }

        const getDriverSum = async () => {
            const filterData = {
                fromDate: filterDateDriverSummary?.firstDate,
                toDate: filterDateDriverSummary?.secondDate,
            }
            try {
                const response = await getDriverSummary(filterData);
                if (response) {
                    setDriverSummary(response);
                }
            } catch (error) {
                message.error(error.message || error.response.data.message || error.response.data.error)
            }
        }

        getVehicleTypeSum();
        getDriverSum();
    }, [filterDateDriverSummary, filterDateVehicleTypeSummary])

    useEffect(() => {
        const getTripTypeSum = async () => {
            const filterData = {
                fromDate: filterDateTripTypeSummary?.firstDate,
            }
            try {
                const response = await getTripTypeSummary(filterData);
                if (response) {
                    setTripTypeSummary(response);
                }
            } catch (error) {
                message.error(error.message || error.response.data.message || error.response.data.error)
            }
        }
        getTripTypeSum();
    }, [filterDateTripTypeSummary])

    // TripSummary
    const TripData = [
        { label: 'New Bookings', value: tripData.newBooking, color: '#3266CC' },
        { label: 'Accepted', value: tripData.accepted, color: '#109618' },
        { label: 'Proceeded', value: tripData.proceeded, color: '#FF9900' },
        { label: 'App Allotted', value: tripData.appAlloted, color: '#109618' },
        { label: 'Manual Allotted', value: tripData.manualAlloted, color: '#990099' },
        { label: 'Completed', value: tripData.completed, color: '#008080' },
        { label: 'Cancelled', value: tripData.cancelled, color: '#DC3910' },
        { label: 'Faked', value: tripData.faked, color: '#8A2BE2' },
    ];

    // TotalTripSummary
    const TotalTripData = [
        totalTripData.Sunday,
        totalTripData.Monday,
        totalTripData.Tuesday,
        totalTripData.Wednesday,
        totalTripData.Thursday,
        totalTripData.Friday,
        totalTripData.Saturday,
    ];

    // DriverManagementSummary
    const DriverManagementSummaryData = [
        { label: 'Drivers', value: driverSummary?.drivers, color: '#3266CC' },
        { label: 'Pending Requests', value: driverSummary?.pendingDrivers, color: '#109618' },
        { label: 'Rejected Requests', value: driverSummary?.rejectedDrivers, color: '#FF9900' },
        { label: 'Expired Drivers', value: driverSummary?.expiredDrivers, color: '#109618' },
        { label: 'Lead Drivers', value: driverSummary?.leadDrivers, color: '#990099' }
    ];

    //VehicleTypeSummary
    const VehicleTypeSummaryData = [
        vehicleTypeSummary?.sedan,
        vehicleTypeSummary?.suv,
        vehicleTypeSummary?.suvPlus,
        vehicleTypeSummary?.muv,
        vehicleTypeSummary?.tempo
    ];

    // EmployeeSummary
    // const TotalData = [50, 125, 100, 120, 50, 80, 160];
    // const AllottedData = [20, 40, 60, 90, 70, 100, 50];

    // const tripTypeSummaryData = {
    //     oneWay: tripTypeSummary?.oneWay || [0, 0, 0, 0, 0, 0, 0],
    //     roundWay:tripTypeSummary?.roundWay || [0, 0, 0, 0, 0, 0, 0],
    //     rental: tripTypeSummary?.rental || [0, 0, 0, 0, 0, 0, 0],
    //     localDrop: tripTypeSummary?.localDrop || [0, 0, 0, 0, 0, 0, 0]
    // }

    // AttendenceSummary
    const PresentData = [70, 125, 70, 110, 90, 40];
    const AbsentData = [50, 110, 90, 90, 110, 80];

    // TicketSummary
    const TicketData = [
        { label: 'Solved', value: 120, color: '#3266CC' },
        { label: 'Pending', value: 90, color: '#FE9900' },
        { label: 'Rejected', value: 40, color: '#DC3910' },
    ];

    // TicketDetails
    const TicketCardsData = [
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        }, {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        }, {
            ticketId: "TID001",
            employeeId: "EMPID001",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID002",
            employeeId: "EMPID002",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
        {
            ticketId: "TID003",
            employeeId: "EMPID003",
            employeeName: "Marixsonjoe",
            reason: "Address Change",
            description: "I am changing my house from Avadi to Velacherry so please....",
            onClick: () => { }
        },
    ];

    return (
        <div className='flex flex-col gap-y-6 p-2  lg:p-8 overflow-y-auto h-[calc(100vh-50px)] md:h-[calc(100vh-10px)]'>
            <p className='text-3xl font-semibold pb-4 pt-2'>Welcome {userName} !</p>
            <div className="flex justify-between flex-wrap gap-2 w-full">
                <TripSummary
                    data={TripData}
                    allVehicle={allVehicle}
                    tripTypeData={tripTypeData}
                    filters={filtersTripSummary}
                    setFilters={setFiltersTripSummary}
                    filterDate={filterDateTripSummary}
                    setFilterDate={setFilterDateTripSummary}
                />
                <TotalTripSummary
                    data={TotalTripData}
                    allVehicle={allVehicle}
                    tripTypeData={tripTypeData}
                    filters={filtersTotalTripSummary}
                    setFilters={setFiltersTotalTripSummary}
                    filterDate={filterDateTotalTripSummary}
                    setFilterDate={setFilterDateTotalTripSummary}
                />
            </div>
            <div className="flex justify-between flex-wrap gap-3 w-full">
                <TripTypeSummary
                    tripTypeSummaryData={tripTypeSummary}
                    filterDate={filterDateTripTypeSummary}
                    setFilterDate={setFilterDateTripTypeSummary}
                />
                <DriverManagementSummary
                    data={DriverManagementSummaryData}
                    // allVehicle={allVehicle}
                    // tripTypeData={tripTypeData}
                    // filters={filtersTripSummary}
                    // setFilters={setFiltersTripSummary}
                    filterDate={filterDateDriverSummary}
                    setFilterDate={setFilterDateDriverSummary}
                />
                <VehicleTypeSummary
                    data={VehicleTypeSummaryData}
                    // allVehicle={allVehicle}
                    // tripTypeData={tripTypeData}
                    // filters={filtersTotalTripSummary}
                    // setFilters={setFiltersTotalTripSummary}
                    filterDate={filterDateVehicleTypeSummary}
                    setFilterDate={setFilterDateVehicleTypeSummary}
                />

            </div>
            <div className="flex justify-between flex-wrap gap-2 w-full">
                <TicketSummary
                    data={TicketData}
                />
                <TicketDetails
                    data={TicketCardsData}
                />
            </div>
        </div>
    );
}
