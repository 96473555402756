import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CommonContextProvider } from "../../context-storage/CommonServicesHandler";
import Navbar from "../navbar/Navbar";

export default function NavbarLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, [location]);

  useEffect(() => {
    const accessToken = localStorage.getItem("CC_ADMIN_TOKEN");
    if (!accessToken) {
      if (url !== "/account-deletion") {
        navigate("/login");
      }
    } else {
      if (url === "/") {
        navigate("/home");
      }
    }
  }, []);

  return (
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
      <CommonContextProvider>
        <div className="h-screen overflow-hidden w-full">
          {/* {children} */}
          <Outlet />
        </div>
      </CommonContextProvider>
    </Navbar>
  );
}
