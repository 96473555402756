import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../reusable/Table/Table";
import TableHead from "../../../reusable/Table/TableHead";
import TableCell from "../../../reusable/Table/TableCell";
import StatusCard from "../../../reusable/Table/TripStatus";
import DropDown from "../../../reusable/DropDown";
import DateInput from "../../../reusable/DateInput";
import Input from "../../../reusable/Input";
import { Modal, Popover, message } from "antd";
import Button from "../../../reusable/Button";
import menuIcon from "./component/menuIcon";
import AssignDriverModal from "../components/manualAllotModal";
import Filters from "../components/Filters";
import { copyToClipboard } from "../../../../pages/utils/utils";
import DateUtils from "../../../../pages/utils/DateUtils";
import { updateRide } from "../../../../services/TripManagementServices";

export default function AcceptedTable({
  tripData,
  getRides,
  tripStatus,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRideIdSearch,
  handleTripTypeFilter,
  handleCabTypeFilter,
  handleFromDateFilter,
  handleToDateFilter,
  rideId,
  vehicles,
  tripTypeData,
  tripTypeFilter,
  cabType,
  fromDate,
  toDate,
  rowCount,
}) {

  const [confirmationModal, setConfirmationModal] = useState({
    state: false,
    action: "",
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isManualAllotedModal, setIsManualAllotedModal] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);


  // Function to identify the particular action
  async function makeActions(row, action) {
    if (action === "copy") {
      copyToClipboard(row);
    }
    else if (action === "manualAllot") {
      setIsManualAllotedModal(true);
    }
    else {
      try {
        const response = await updateRide(row.id, { tripStatus: 6 }) //Move to cancelled
        message.success('Trip cancelled sucessfully!');
        getRides(tripStatus, rowsPerPage, page + 1);
      }
      catch (error) {
        message.error(error.message || error.response.data.message || error.response.data.error);
      }
    }
  }

  // Function to get the selected row
  function handlePopoverClick(row) {
    if (openPopoverId === row.rideId) {
      setOpenPopoverId(null);
    } else {
      setSelectedRow(row);
      setOpenPopoverId(row.rideId);
    }
  }

  const actionsContent = (
    <div className=" flex flex-col">
      <div
        className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-1 "
        onClick={() => { makeActions(selectedRow, "copy"); setOpenPopoverId(null) }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 9.16663C5 6.8096 5 5.63109 5.73223 4.89886C6.46447 4.16663 7.64297 4.16663 10 4.16663H12.5C14.857 4.16663 16.0355 4.16663 16.7677 4.89886C17.5 5.63109 17.5 6.8096 17.5 9.16663V13.3333C17.5 15.6903 17.5 16.8688 16.7677 17.601C16.0355 18.3333 14.857 18.3333 12.5 18.3333H10C7.64297 18.3333 6.46447 18.3333 5.73223 17.601C5 16.8688 5 15.6903 5 13.3333V9.16663Z"
            stroke="#667085"
            stroke-width="2"
          />
          <path
            d="M5 15.8333C3.61929 15.8333 2.5 14.714 2.5 13.3333V8.33329C2.5 5.19059 2.5 3.61925 3.47631 2.64293C4.45262 1.66663 6.02397 1.66663 9.16667 1.66663H12.5C13.8807 1.66663 15 2.78592 15 4.16663"
            stroke="#667085"
            stroke-width="2"
          />
        </svg>
        <div style={{ fontSize: "12px" }}>Copy</div>
      </div>
      <div
        className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-1 "
        onClick={() => { makeActions(selectedRow, "manualAllot"); setOpenPopoverId(null) }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#00904D"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
        <div style={{ fontSize: "12px" }}>Manual Allot</div>
      </div>

      <div
        className=" flex items-center hover:bg-gray-200 pl-2 rounded-md cursor-pointer  pr-3 gap-2  py-1 "
        onClick={() => { makeActions(selectedRow, "cancelTrip"); setOpenPopoverId(null) }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 14 14" fill="#ff0000">
          <path d="M13 1L1 13M1 1L13 13" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div style={{ fontSize: "12px" }}>Cancel Trip</div>
      </div>
    </div>
  );

  const columns = [
    {
      id: "rideId",
      name: <TableHead>Trip ID</TableHead>,
      cell: (row) => (
        <TableCell>
          <Link
            className=" text-primary-blue"
            to={{ pathname: `/trip-management/${row.rideId}/${row.tripMode.tripName}` }}
            state={row}
          >
            {row.rideId}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "pickupDate",
      name: <TableHead>Pickup Date</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertDate(row.dateOfRide)}</TableCell>,
    },
    {
      id: "pickupTime",
      name: <TableHead>Pickup Time</TableHead>,
      cell: (row) => <TableCell>{DateUtils.convertTime(row.pickUpTime)}</TableCell>,
    },
    {
      id: "tripType",
      name: <TableHead>Trip Type</TableHead>,
      cell: (row) => <TableCell>{row.tripMode.tripName}</TableCell>,
    },
    {
      id: "fromLocation",
      name: <TableHead>From Location</TableHead>,
      cell: (row) => <TableCell>{row.pickUpLocation}</TableCell>,
    },

    {
      id: "toLocation",
      name: <TableHead>To Location</TableHead>,
      cell: (row) => <TableCell>{row.tripMode.id == 3 ? "-" : row.dropLocation}</TableCell>,
    },
    {
      id: "mode",
      name: <TableHead>Booking Mode</TableHead>,
      cell: (row) => (
        <TableCell>
          <StatusCard status={row.bookingMode.bookingMode} />
        </TableCell>
      ),
    },
    {
      id: "actions",
      name: <TableHead>Actions</TableHead>,
      cell: (row) => (
        <TableCell>
          <Popover
            content={actionsContent}
            placement="leftTop"
            trigger="click"
            className=" cursor-pointer"
            onOpenChange={() => handlePopoverClick(row)}
            style={{ fontFamily: "Poppins" }}
            open={openPopoverId === row.rideId}
          >
            {menuIcon()}
          </Popover>
          {/* <div className="flex justify-center w-full gap-2 ">
            <button className="bg-[#1849A9] rounded text-[#fff] px-2 py-0.5 font-bold" onClick={() => makeActions(row)}>
              M
            </button>
          </div> */}
        </TableCell>
      ),
    },
  ];

  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex  flex-wrap  lg:gap-8 gap-2 w-full justify-center lg:flex-nowrap  lg:pl-2  lg:pr-10  lg:justify-between items-center mb-5  lg:mb-10  mt-2 lg:mt-6">
        <Filters
          rideId={rideId}
          handleRideIdSearch={handleRideIdSearch}
          tripTypeData={tripTypeData}
          tripTypeFilter={tripTypeFilter}
          handleTripTypeFilter={handleTripTypeFilter}
          vehicles={vehicles}
          cabType={cabType}
          handleCabTypeFilter={handleCabTypeFilter}
          fromDate={fromDate}
          handleFromDateFilter={handleFromDateFilter}
          toDate={toDate}
          handleToDateFilter={handleToDateFilter}
        />
      </div>
      <Table
        columns={columns}
        rows={tripData.acceptedTable.acceptedTableData}
        rowCount={rowCount}
        getRides={getRides}
        tripStatus={tripStatus}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Modal
        title={
          <>
            <div className="flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="orange"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'normal' }}>Confirmation</h1>
            </div>
          </>
        }
        visible={confirmationModal.state}
        width={"500px"}
        footer={() => (
          <>
            <div className="flex gap-3 justify-end">
              <Button
                text={"Cancel"}
                textColor={"#1849A9"}
                width={"80px"}
                height={"30px"}
                bgColor={"#fff"}
                borderColor={"#1849A9"}
                fontSize={"12px"}
                onClick={() =>
                  setConfirmationModal({ state: false, action: "" })
                }
              />
              <Button
                text={"Ok"}
                textColor={"#fff"}
                width={"80px"}
                height={"30px"}
                fontSize={"12px"}
                onClick={() => {
                  makeActions(selectedRow, confirmationModal.action);
                  setConfirmationModal({ state: false, action: "" });
                }}
              />
            </div>
          </>
        )}
      >
        <div className="pr-5 pt-5 pb-5">
          <h1 className="text-base" style={{ fontSize: '13px', fontWeight: 'normal' }}>
            Are you sure want to{" "}
            <em>
              <b>{confirmationModal.action}</b>
            </em>{" "}
            the ride ?
          </h1>
        </div>
      </Modal>
      <AssignDriverModal
        isManualAllotedModal={isManualAllotedModal}
        setIsManualAllotedModal={setIsManualAllotedModal}
        selectedRow={selectedRow}
        tripStatus={tripStatus}
        getRides={getRides}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </div>
  );
}
