import { useContext, useEffect, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { useLocation, useParams } from "react-router-dom";
import MobileNumberInput from "../../components/reusable/MobileNumberInput";
import Input from "../../components/reusable/Input";
import DropDown from "../../components/reusable/DropDown";
import DateInput from "../../components/reusable/DateInput";
import TimeInput from "../../components/reusable/TimeInput";
import PopoverContent from "./components/PopoverContent";

import LiveTrackingPopoverContent from "./components/LiveTrackingPopoverContent";
import {
  getSelectedTimeObject,
  getTripStatus,
  tripTimeDropDownData,
} from "../utils/utils";
import DateUtils from "../utils/DateUtils";
import CommonContext from "../../context-storage/CommonServicesHandler";
import {
  getEstimatedRideFare,
  getTripById,
  updateTripData,
  updateFinalFare,
} from "../../services/TripManagementServices";
import {
  getDriversIdDropdown,
  getFilteredDriverIds,
} from "../../services/DriverManagementServices";
import { Card, Popover, message } from "antd";
import Button from "../../components/reusable/Button";

import driverImage from "./assets/driver-image.png";
import vehicleImage from "./assets/suv-image.png";

import StatusCard from "../../components/reusable/Table/TripStatus";
import { validateFields } from "./validations/validate";

import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import AssignDriverModal from "../../components/tables/trip-management/components/manualAllotModal";
import CustomerDetailsCard from "../customer-management/CustomerDetailsCard";
import { isNotValidTime } from "./validations/validations";
import Table from "../../components/reusable/Table/Table";
import TableHead from "../../components/reusable/Table/TableHead";
import TableCell from "../../components/reusable/Table/TableCell";

export default function TripDetailsPage() {
  const [isEditFinalFare, setIsEditFinalFare] = useState(true);
  const [finalFare, setFinalFare] = useState(0);
  const [finalBaseFare, setFinalBaseFare] = useState(0);
  const [finalDiverFee, setFinalDiverFee] = useState(0);
  const [finalTotalDistance, setFinalTotalDistance] = useState(0);
  const [finalTotalTollFee, setFinalTotalTollFee] = useState(0);
  const [finalTotalStatePermitFee, setFinalTotalStatePermitFee] = useState(0);
  const [finalTotalParkingFee, setFinalTotalParkingFee] = useState(0);
  const [finalOtherFee, setFinalOtherFee] = useState(0);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBFGIMsW3amuqc-k8nhYQtLmnPHy7opdLg",
    libraries: ["places"],
  });
  const [tripDetails, setTripDetails] = useState({});
  const [tripStatus, setTripStatus] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [userInputs, setUserInputs] = useState({
    tripType: "",
    fromLocation: "",
    toLocation: "",
    tripTime: "",
    tripDate: "",
    vehicleType: "",
    returnDate: "",
    pickUpDate: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [tripDriverLogs, setTripDriverLogs] = useState([]);

  const [vehicleTypeDropdown, setVehicleTypeDropdown] = useState([]);
  const [distanceAndDurationVal, setDistanceAndDurationVal] = useState({
    distance: "",
    duration: "",
  });
  const [distanceAndDuration, setDistanceAndDuration] = useState([
    { distance: 20, duration: 2 },
    { distance: 30, duration: 3 },
    { distance: 40, duration: 4 },
    { distance: 50, duration: 5 },
    { distance: 60, duration: 6 },
    { distance: 70, duration: 7 },
    { distance: 80, duration: 8 },
    { distance: 90, duration: 9 },
    { distance: 100, duration: 10 },
    { distance: 110, duration: 11 },
    { distance: 120, duration: 12 },
    { distance: 150, duration: 15 },
  ]);

  const [isManualAllotedModal, setIsManualAllotedModal] = useState(false);
  const [driverIds, setDriverIds] = useState([]);

  const { id, tripTypeParams } = useParams();
  let { state } = useLocation();

  const [customer, setCustomer] = useState({
    customerId: "",
    profileImageUrl: "",
    customerName: "",
    customerPhoneNumber: "",
    customerEmail: "",
    customerState: {},
    customerDistrict: {},
    customerStreet: "",
    customerHouseNumber: "",
    sum: {},
    tripRefral: [],
    signUpRefral: [],
    cashBackRefral: [],
  });
  const [profileImage, setProfileImage] = useState(undefined);

  const {
    tripManagementActiveKey,
    tripTypeData,
    vehicleTypeData,
    setTripMode,
  } = useContext(CommonContext);

  //google places api

  async function getTrip() {
    try {
      console.log(state);
      const response = await getTripById(state.id);
      if (response.data) {
        setTripDetails(response.data);
        const customerData = response.data?.customerUser;
        setFinalFare(response.data.totalEndFare || 0);
        setFinalBaseFare(response.data.totalEndBaseFare || 0);
        setFinalDiverFee(response.data.totalEndDriverFee || 0);
        setFinalTotalDistance(response.data.totalKm || 0);
        setFinalTotalTollFee(response.data.totalTollFees || 0);
        setFinalTotalStatePermitFee(response.data.totalStatePermit || 0);
        setFinalTotalParkingFee(response.data.totalParkingFees || 0);
        setFinalOtherFee(response.data.otherFees || 0);
        setTripDriverLogs(response.data.tripDriverLogs || []);
        setCustomer({
          ...customer,
          customerId: customerData.id,
          customerName: customerData.userName || "",
          customerPhoneNumber: customerData.mobileNumber || "",
          customerEmail: customerData.email || "",
          customerState: { stateName: customerData.state } || {},
          customerDistrict: { districtName: customerData.district } || {},
          customerStreet: customerData.street || "",
          customerHouseNumber: customerData.houseNumber || "",
          // below 4
          sum: customerData.sum,
          tripRefral: response.data.tripRefral,
          signUpRefral: response.data.signUpRefral,
          cashBackRefral: response.data.cashBackRefral,
        });
        setProfileImage(customerData.imageUrl);
      }
    } catch (error) {
      message.error(
        error.message ||
          error.response.data.message ||
          error.response.data.error
      );
    }
  }

  useEffect(() => {
    getTrip();
  }, []);

  useEffect(() => {
    setTripMode(userInputs.tripType.id);
  }, [userInputs.tripType]);

  useEffect(() => {
    setVehicleTypeDropdown(vehicleTypeData);
  }, [vehicleTypeData]);

  useEffect(() => {
    if (Object.keys(tripDetails).length > 0) {
      setTripMode(state.tripMode.id);
      // setTripMode(tripDetails?.tripType)
      console.log(tripDetails);
      setUserInputs({
        tripType:
          tripDetails.tripType === 1
            ? { id: 1, tripName: "One Way" }
            : tripDetails.tripType === 2
            ? { id: 2, tripName: "Round Way" }
            : tripDetails.tripType === 3
            ? { id: 3, tripName: "Rental" }
            : { id: 4, tripName: "Local Drop" },
        fromLocation: tripDetails.pickUpLocation,
        toLocation: tripDetails.dropLocation,
        tripTime: getSelectedTimeObject(tripDetails?.pickUpTime),
        tripDate: tripDetails.dateOfRide,
        vehicleType: tripDetails.carMode,
        returnDate: tripDetails.rDateOfRide,
        pickUpDate: tripDetails.dateOfRide,
      });
      setDistanceAndDurationVal({
        distance: tripDetails.distanceInKm,
        duration: tripDetails.rentalDuration,
      });
      // getFilteredDriverIds(tripDetails.carMode.vehicleTypeId)
    }
  }, [tripDetails]);

  useEffect(() => {
    const tripStatus = getTripStatus(tripManagementActiveKey);
    setTripStatus(tripStatus);
  }, [tripManagementActiveKey]);

  async function handleFareEstimate() {
    let inputFields;

    if (+tripDetails.tripType === 1 || +tripDetails.tripType === 2) {
      inputFields = {
        fromLocation: userInputs.fromLocation,
        toLocation: userInputs.toLocation,
        tripDate: userInputs.tripDate,
        tripTime: userInputs.tripTime.value,
        vehicleType: userInputs.vehicleType,
        mobileNumber: userInputs.mobileNumber,
        pickupDate: userInputs.pickUpDate,
        returnDate: userInputs.returnDate,
        tripType: userInputs.tripType,
      };
    } else if (+tripDetails.tripType === 3) {
      inputFields = {
        fromLocation: userInputs.fromLocation,
        pickupDate: userInputs.pickupDate,
        tripTime: userInputs.tripTime.value,
        distanceAndDurationVal: distanceAndDurationVal,
        vehicleType: userInputs.vehicleType,
        mobileNumber: userInputs.mobileNumber,
        tripType: userInputs.tripType,
      };
    } else {
      inputFields = {
        fromLocation: userInputs.fromLocation,
        toLocation: userInputs.toLocation,
        mobileNumber: userInputs.mobileNumber,
        tripType: userInputs.tripType,
      };
    }
    if (!isNotValidTime(userInputs?.tripTime, userInputs?.tripDate)) {
      setErrors({ tripTime: "Enter the valid time" });
      return;
    }

    // const errors = validateFields(inputFields)
    // setErrors(errors);

    // if (Object.keys(errors).length === 0) {

    // Valid form, proceed with submission
    setLoading(true);

    let estimatedFarePayload;

    if (
      +userInputs.tripType.id === 1 ||
      +userInputs.tripType.id === 2 ||
      +userInputs.tripType.id === 4
    ) {
      estimatedFarePayload = {
        destinationLocation: userInputs.toLocation || "",
        pickupLocation: userInputs.fromLocation || "",
        carType: parseInt(userInputs.vehicleType.id) || "",
        tripMode: parseInt(userInputs.tripType.id) || "",
        date:
          +userInputs.tripType.id === 1 || userInputs.tripType.id === 4
            ? userInputs.tripDate
            : +userInputs.tripType.id === 2
            ? userInputs.pickUpDate
            : "" || "",
        rDate: userInputs.returnDate || "",
        time: userInputs.tripTime || "",
        rewardPoints: 100,
      };
    } else if (+tripDetails.tripType === 3) {
      estimatedFarePayload = {
        pickupLocation: userInputs.fromLocation || "",
        carType: parseInt(userInputs.vehicleType.id) || "",
        tripMode: parseInt(userInputs.tripType.id) || "",
        date: userInputs.tripDate || "",
        time: userInputs.tripTime || "",
        rewardPoints: 100,
        rentalDistance: distanceAndDurationVal.distance,
      };
    }

    try {
      //Getting the estimated fare details
      const response = await getEstimatedRideFare(estimatedFarePayload);
      const estimatedFareData = response.data;

      let updateTripPayload;

      if (userInputs.tripType.id === 3) {
        updateTripPayload = {
          pickUpLocation: userInputs.fromLocation,
          dateOfRide: userInputs.pickupDate,
          pickUpTime: userInputs.tripTime.value,
          carType: +userInputs.vehicleType.id,
          distanceInKm: distanceAndDurationVal.distance,
          rentalDuration: distanceAndDurationVal.duration,
          totalFare: estimatedFareData.estimatedRateWithDriverFee,
          // "tripStatus": 1,
          tripMode: 3,
          // "customerId": 1,
          // "bookingModeId": 3
        };
      } else {
        updateTripPayload = {
          dropLocation: userInputs.toLocation || "",
          pickUpLocation: userInputs.fromLocation || "",
          carType: parseInt(userInputs.vehicleType.id) || "",
          tripType: parseInt(userInputs.tripType.id) || "",
          dateOfRide:
            +userInputs.tripType.id === 1 || userInputs.tripType.id === 4
              ? userInputs.tripDate
              : +userInputs.tripType.id === 2
              ? userInputs.pickUpDate
              : "" || "",
          rDateOfRide: userInputs.returnDate || "0",
          pickUpTime: userInputs.tripTime.value || "",
          distanceInKm:
            +userInputs.tripType.id === 1 ||
            +userInputs.tripType.id === 2 ||
            +userInputs.tripType.id === 4
              ? estimatedFareData.distanceInKm
              : estimatedFareData.distance,
          totalFare: estimatedFareData.estimatedRateWithDriverFee,
          baseFare: estimatedFareData.estimatedRate,
          driverFee: estimatedFareData.totalDriverFee,
          // tripStatus: 1,
          // customerId: 1,
          // bookingModeId: 3
        };
      }

      //Booking the new ride
      const updateTrip = await updateTripData(
        tripDetails.id,
        updateTripPayload
      );
      message.success("Trip Updated Successfully");
      setIsEdit(false);
      setLoading(false);
      setErrors({});
    } catch (error) {
      setLoading(false);
      message.error(
        error.message ||
          error.response.data.error ||
          error.response.data.message
      );
    }

    // }
  }

  function handleTripTypeChange(item) {
    setUserInputs({
      tripType: "",
      fromLocation: "",
      toLocation: "",
      tripTime: "",
      tripDate: "",
      vehicleType: "",
      returnDate: "",
      pickUpDate: "",
      tripType: item,
    });
  }

  if (!isLoaded) {
    return <div></div>;
  }

  const handleFinalFareEstimate = async () => {
    const data = {
      id: tripDetails.id,
      finalFare,
      finalBaseFare,
      finalDiverFee,
      finalTotalDistance,
      finalTotalTollFee,
      finalTotalStatePermitFee,
      finalTotalParkingFee,
      finalOtherFee,
    };

    const updateTrip = await updateFinalFare(data);

    if (updateTrip) {
      message.success("Trip Updated Successfully");
      setIsEditFinalFare(true);
    }
  };

  return (
    <>
      <PageLayout
        title={"Trip Management"}
        description={"View your trip details here."}
        buttonWidth={"120px"}
        // BreadcrumbsUrl={BreadcrumbsUrl}
      >
        <div className="h-[calc(100vh-200px)] md:h-[calc(100vh-150px)] overflow-y-auto">
          {/* Customer Details card */}
          <CustomerDetailsCard
            customerDetails={tripDetails?.customerUser}
            // isEdit={isEdit}
            setIsEdit={setIsEdit}
            customer={customer}
            setCustomer={setCustomer}
            errors={errors}
            // states={states}
            // districts={districts}
            // getAllDistrict={getAllDistrict}
            profileImage={profileImage}
            driverImage={driverImage}
            // handleProfileImage={handleProfileImage}
            // updateCustomerDetails={updateCustomerDetails}
            setErrors={setErrors}
            loading={loading}
            getCustomer={getTrip}
            disableEdit={true}
          />

          {/* Trip Details card */}

          <div className=" w-full px-1 flex my-7 justify-center items-center ">
            <Card className=" w-full  shadow-sm">
              <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                <div className=" w-full flex justify-between items-center">
                  <div
                    className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                    style={{ fontSize: "16px" }}
                  >
                    Trip Details
                  </div>
                  <div className="  w-full flex justify-end items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      onClick={() => setIsEdit(!isEdit)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-x-12 md:pb-3">
                  <Input
                    label={"Route ID"}
                    disabled={true}
                    placeholder={"Route id"}
                    value={tripDetails.rideId}
                    mandate={true}
                  />
                  <DropDown
                    label={"Trip type"}
                    placeholder={"Select trip type"}
                    displayValue={"tripName"}
                    disabled={!isEdit ? true : false}
                    data={tripTypeData}
                    value={userInputs.tripType}
                    setValue={(item) => handleTripTypeChange(item)}
                    mandate={true}
                  />
                  {userInputs.tripType.tripName === "One Way" ||
                  userInputs.tripType.tripName === "Round Way" ||
                  userInputs.tripType.tripName === "Local Drop" ? (
                    <>
                      <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full">
                        <Input
                          label={"From Location "}
                          disabled={!isEdit ? true : false}
                          placeholder={"Enter from location"}
                          value={userInputs.fromLocation}
                          onChange={(e) =>
                            setUserInputs({
                              ...userInputs,
                              fromLocation: e.target.value,
                            })
                          }
                          onBlur={(e) =>
                            setUserInputs({
                              ...userInputs,
                              fromLocation: e.target.value,
                            })
                          }
                          mandate={true}
                        />
                      </Autocomplete>
                      <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full">
                        <Input
                          disabled={!isEdit ? true : false}
                          label={"To Location"}
                          placeholder={"Enter to location"}
                          value={userInputs.toLocation}
                          onBlur={(e) =>
                            setUserInputs({
                              ...userInputs,
                              toLocation: e.target.value,
                            })
                          }
                          onChange={(e) =>
                            setUserInputs({
                              ...userInputs,
                              toLocation: e.target.value,
                            })
                          }
                          mandate={true}
                        />
                      </Autocomplete>
                    </>
                  ) : userInputs.tripType.tripName === "Rental" ? (
                    <>
                      <Autocomplete className="flex flex-col min-w-[200px]  max-w-[300px] w-full">
                        <Input
                          label={"Pickup Location"}
                          disabled={!isEdit ? true : false}
                          placeholder={"Enter pickup location"}
                          value={userInputs.fromLocation}
                          onChange={(e) =>
                            setUserInputs({
                              ...userInputs,
                              fromLocation: e.target.value,
                            })
                          }
                          onBlur={(e) =>
                            setUserInputs({
                              ...userInputs,
                              fromLocation: e.target.value,
                            })
                          }
                          mandate={true}
                        />
                      </Autocomplete>
                    </>
                  ) : (
                    ""
                  )}

                  {userInputs.tripType.tripName === "One Way" ||
                  userInputs.tripType.tripName === "Rental" ||
                  userInputs.tripType.tripName === "Local Drop" ? (
                    <>
                      <DateInput
                        label={"Trip date"}
                        disabled={!isEdit ? true : false}
                        placeholder={"Select trip date"}
                        value={DateUtils.convertDate(userInputs.tripDate)}
                        setValue={(item) =>
                          setUserInputs({ ...userInputs, tripDate: item })
                        }
                        mandate={true}
                      />
                    </>
                  ) : userInputs.tripType.tripName === "Round Way" ? (
                    <>
                      <DateInput
                        label={"Pickup date"}
                        disabled={!isEdit ? true : false}
                        placeholder={"Select trip date"}
                        value={DateUtils.convertDate(userInputs.pickUpDate)}
                        setValue={(item) =>
                          setUserInputs({ ...userInputs, pickUpDate: item })
                        }
                        mandate={true}
                      />
                      <DateInput
                        label={"Return date"}
                        disabled={!isEdit ? true : false}
                        placeholder={"Select return date"}
                        value={DateUtils.convertDate(userInputs.returnDate)}
                        setValue={(item) =>
                          setUserInputs({ ...userInputs, returnDate: item })
                        }
                        mandate={true}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {userInputs.tripType.tripName === "One Way" ||
                  userInputs.tripType.tripName === "Round Way" ||
                  userInputs.tripType.tripName === "Local Drop" ? (
                    <>
                      {/* <TimeInput
                        label={"Trip time"}
                        disabled={!isEdit ? true : false}
                        placeholder={"Select trip time"}
                        value={DateUtils.convertTime(userInputs.tripTime)}
                        setValue={(item) => setUserInputs({ ...userInputs, tripTime: item })}
                        mandate={true}
                      /> */}
                      <DropDown
                        label={"Trip Time"}
                        placeholder={"Select trip time"}
                        displayValue={"label"}
                        data={tripTimeDropDownData}
                        value={userInputs.tripTime}
                        setValue={(item) =>
                          setUserInputs({ ...userInputs, tripTime: item })
                        }
                        mandate={true}
                        error={errors.tripTime}
                        disabled={!isEdit ? true : false}
                      />
                    </>
                  ) : userInputs.tripType.tripName === "Rental" ? (
                    <>
                      <DropDown
                        label={"Distance (in Km)"}
                        placeholder={"Select distance"}
                        displayValue={"distance"}
                        data={distanceAndDuration}
                        value={distanceAndDurationVal}
                        setValue={(val) => setDistanceAndDurationVal(val)}
                        mandate={true}
                        disabled={!isEdit ? true : false}

                        // error={errors.distance}
                      />
                      <DropDown
                        label={"Duration (in Hrs)"}
                        placeholder={"Select duration"}
                        displayValue={"duration"}
                        data={distanceAndDuration}
                        value={distanceAndDurationVal}
                        setValue={(val) => setDistanceAndDurationVal(val)}
                        mandate={true}
                        disabled={!isEdit ? true : false}
                        // error={errors.duration}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <DropDown
                    label={"Vehicle type"}
                    placeholder={"Select vehicle type"}
                    disabled={!isEdit ? true : false}
                    data={vehicleTypeDropdown}
                    displayValue={"carType"}
                    value={userInputs.vehicleType}
                    setValue={(item) =>
                      setUserInputs({ ...userInputs, vehicleType: item })
                    }
                    mandate={true}
                  />
                  {
                    // ((userInputs.tripType.tripName === "Round Way") || (userInputs.tripType.tripName === "Rental")) && <Input
                    //   disabled={true}
                    //   invisible
                    // />
                  }
                </div>
              </div>

              {/* Update and Cancel buttons */}
              {isEdit && (
                <div className="flex justify-center items-center gap-3 mt-5">
                  <Button
                    text={"Cancel"}
                    width={"100px"}
                    bgColor={"#fff"}
                    height={"35px"}
                    onClick={() => {
                      setIsEdit(false);
                      setErrors({});
                    }}
                    textColor={"#1849A9"}
                    borderColor={"#1849A9"}
                    fontSize={"12px"}
                  />
                  <Button
                    text={"Update"}
                    width={"100px"}
                    bgColor={"#1849A9"}
                    height={"35px"}
                    onClick={handleFareEstimate}
                    textColor={"#fff"}
                    fontSize={"12px"}
                    loading={loading}
                  />
                </div>
              )}
            </Card>
          </div>

          {/* Fare Details card */}

          <div className=" w-full px-1 my-7 flex justify-center items-center ">
            <Card className=" w-full  shadow-sm">
              <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                <div className=" w-full flex justify-between items-center">
                  <div
                    className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                    style={{ fontSize: "16px" }}
                  >
                    Estimated Fare Details
                  </div>
                  {/* <div className="  w-full flex justify-end items-center">
                    <Popover
                      content={<PopoverContent />}
                      placement="leftTop"
                      trigger="click"
                      className=" cursor-pointer"
                    >
                      {MenuIcon()}
                    </Popover>
                  </div> */}
                </div>
                <div className=" w-full">
                  <Input
                    label={"Estimated Fare"}
                    placeholder={"Enter estimated fare"}
                    value={
                      "₹ " + tripDetails.totalFare?.toLocaleString("hi") + "/-"
                    }
                    setValue={""}
                    disabled={true}
                  />
                </div>
                <div className=" w-full flex  flex-wrap  justify-center items-center  lg:justify-between gap-3 lg:gap-6 pb-4">
                  <Input
                    label={"Base Fare"}
                    placeholder={"Enter base fare"}
                    value={
                      "₹ " + tripDetails.baseFare?.toLocaleString("hi") + "/-"
                    }
                    setValue={""}
                    disabled={true}
                  />
                  <Input
                    label={"Driver Fee"}
                    placeholder={"Enter driver fee"}
                    value={
                      "₹ " + tripDetails.driverFee?.toLocaleString("hi") + "/-"
                    }
                    setValue={""}
                    disabled={true}
                  />
                  <Input
                    label={"Distance"}
                    placeholder={"Enter distance"}
                    value={
                      tripDetails.distanceInKm?.toLocaleString("hi") + " KM"
                    }
                    setValue={""}
                    disabled={true}
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* Total Fare Details card */}
          {tripStatus === 5 && (
            <div className=" w-full px-1 flex my-7 justify-center items-center ">
              <Card className=" w-full  shadow-sm">
                <div className=" w-full flex flex-col   gap-3 lg:gap-6  rounded-md">
                  <div className=" w-full flex justify-between items-center">
                    <div
                      className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                      style={{ fontSize: "16px" }}
                    >
                      Final Fare Details
                    </div>
                    <div className="  w-full flex justify-end items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        onClick={() => setIsEditFinalFare(false)}
                        className="cursor-pointer"
                      >
                        <path
                          d="M6.87014 2.33314H2.69147C2.37482 2.33314 2.07115 2.45606 1.84725 2.67485C1.62334 2.89364 1.49756 3.19039 1.49756 3.49981V11.6665C1.49756 11.9759 1.62334 12.2726 1.84725 12.4914C2.07115 12.7102 2.37482 12.8331 2.69147 12.8331H11.0488C11.3655 12.8331 11.6691 12.7102 11.893 12.4914C12.1169 12.2726 12.2427 11.9759 12.2427 11.6665V7.58314M11.3473 1.45814C11.5848 1.22608 11.9069 1.0957 12.2427 1.0957C12.5786 1.0957 12.9007 1.22608 13.1382 1.45814C13.3756 1.6902 13.5091 2.00495 13.5091 2.33314C13.5091 2.66133 13.3756 2.97608 13.1382 3.20814L7.4671 8.74981L5.07928 9.33314L5.67624 6.99981L11.3473 1.45814Z"
                          stroke="#344054"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className=" w-full flex  flex-wrap  justify-center  lg:justify-between gap-3 lg:gap-6">
                    <Input
                      label={"Final Fare"}
                      disabled={isEditFinalFare}
                      placeholder={"Final Fare"}
                      value={finalFare}
                      onChange={(e) => setFinalFare(e.target.value)}
                    />
                    <Input
                      label={"Base Fare"}
                      disabled={isEditFinalFare}
                      placeholder={"Base Fare"}
                      value={finalBaseFare}
                      // setValue={setBaseFare}
                      onChange={(e) => setFinalBaseFare(e.target.value)}
                    />
                    <Input
                      label={"Driver Fee"}
                      disabled={isEditFinalFare}
                      placeholder={"Driver Fee"}
                      value={finalDiverFee}
                      // setValue={setDiverFee}
                      onChange={(e) => setFinalDiverFee(e.target.value)}
                    />
                    <Input
                      label={"Total Distance"}
                      disabled={isEditFinalFare}
                      placeholder={"Total Distance"}
                      value={finalTotalDistance}
                      // setValue={totalDistance}
                      onChange={(e) => setFinalTotalDistance(e.target.value)}
                    />
                    <Input
                      label={"Total Toll Fee"}
                      disabled={isEditFinalFare}
                      placeholder={"Total Toll Fee"}
                      value={finalTotalTollFee}
                      // setValue={""}
                      onChange={(e) => setFinalTotalTollFee(e.target.value)}
                    />
                    <Input
                      label={"Total State Permit Fee"}
                      disabled={isEditFinalFare}
                      placeholder={"Total State Permit Fee"}
                      value={finalTotalStatePermitFee}
                      // setValue={""}
                      onChange={(e) =>
                        setFinalTotalStatePermitFee(e.target.value)
                      }
                    />
                    <Input
                      label={"Total Parking Fee"}
                      disabled={isEditFinalFare}
                      placeholder={"Total Parking Fee"}
                      value={finalTotalParkingFee}
                      // setValue={""}
                      onChange={(e) => setFinalTotalParkingFee(e.target.value)}
                    />

                    <Input
                      label={"Other Fee"}
                      disabled={isEditFinalFare}
                      placeholder={"Other Fee"}
                      value={finalOtherFee}
                      // setValue={""}
                      onChange={(e) => setFinalOtherFee(e.target.value)}
                    />
                  </div>
                </div>
                {/* Update and Cancel buttons */}
                {!isEditFinalFare && (
                  <div className="flex justify-center items-center gap-3 mt-5">
                    <Button
                      text={"Cancel"}
                      width={"100px"}
                      bgColor={"#fff"}
                      height={"35px"}
                      onClick={() => {
                        isEditFinalFare(true);
                        setErrors({});
                      }}
                      textColor={"#1849A9"}
                      borderColor={"#1849A9"}
                      fontSize={"12px"}
                    />
                    <Button
                      text={"Update"}
                      width={"100px"}
                      bgColor={"#1849A9"}
                      height={"35px"}
                      onClick={handleFinalFareEstimate}
                      textColor={"#fff"}
                      fontSize={"12px"}
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </div>
          )}

          {/* Trip Closing Details card */}
          {tripStatus === 5 && (
            <div className=" w-full px-1 flex  my-7 justify-center items-center ">
              <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                <div
                  className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                  style={{ fontSize: "16px" }}
                >
                  Trip Closing Details
                </div>
                <>
                  <div className=" w-full flex-wrap gap-5 h-fit py-4 flex justify-between items-center mt-3   ">
                    <Input
                      label={"Start KM Reading"}
                      placeholder={"Start KM Reading"}
                      value={tripDetails.startKm ? tripDetails.startKm : "-"}
                      // setValue={""}
                      disabled={true}
                    />
                    <Input
                      label={"Closing KM Reading"}
                      placeholder={"Closing KM Reading"}
                      value={tripDetails.endKm ? tripDetails.endKm : "-"}
                      // setValue={""}
                      disabled={true}
                    />
                    <Input
                      label={"Total Toll Fee"}
                      disabled={true}
                      placeholder={"Total Toll Fee"}
                      value={
                        "₹ " +
                        tripDetails.totalTollFees?.toLocaleString("hi") +
                        "/-"
                      }
                      // setValue={""}
                    />
                    <Input
                      label={"Total State Permit Fee"}
                      disabled={true}
                      placeholder={"Total State Permit Fee"}
                      value={
                        "₹ " +
                        tripDetails.totalStatePermit?.toLocaleString("hi") +
                        "/-"
                      }
                      // setValue={""}
                    />
                    <Input
                      label={"Total Parking Fee"}
                      disabled={true}
                      placeholder={"Total Parking Fee"}
                      value={
                        "₹ " +
                        tripDetails.totalParkingFees?.toLocaleString("hi") +
                        "/-"
                      }
                      // setValue={""}
                    />

                    <Input
                      label={"Other Fee"}
                      disabled={true}
                      placeholder={"Other Fee"}
                      value={
                        "₹ " +
                        tripDetails.otherFees?.toLocaleString("hi") +
                        "/-"
                      }
                      // setValue={""}
                    />
                  </div>
                </>
              </Card>
            </div>
          )}

          {/* Trip OTP card */}

          <div className=" w-full px-1 flex my-7 justify-center items-center ">
            <Card className=" w-full shadow-sm">
              <div className=" w-full flex flex-col   gap-3 lg:gap-3  rounded-md">
                <div className=" w-full flex justify-between items-center">
                  <div
                    className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                    style={{ fontSize: "16px" }}
                  >
                    OTP Details
                  </div>
                </div>
                <div className=" w-full flex  flex-wrap  justify-center  lg:justify-between gap-2 lg:gap-2 py-4">
                  <Input
                    label={"Start OTP"}
                    placeholder={"Enter start otp"}
                    value={tripDetails.startOtp}
                    setValue={""}
                    disabled={true}
                  />
                  <Input
                    label={"End OTP"}
                    placeholder={"Enter end otp"}
                    value={tripDetails.endOtp}
                    setValue={""}
                    disabled={true}
                  />
                  <div className="hidden md:block">
                    <Input
                      label={"End OTP"}
                      placeholder={"Enter end otp"}
                      value={"54678"}
                      setValue={""}
                      disabled={true}
                      invisible={true}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {/* Driver Allocate card */}

          {tripStatus === 1 && (
            <div className=" w-full px-1 flex  my-7 justify-center items-center ">
              <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                <div className=" w-full h-fit py-4 flex justify-center items-center flex-col gap-5">
                  <div className="text-black text-center font-roboto text-sm leading-5">
                    Please assign driver for this trip !
                  </div>
                  <div className=" w-full flex justify-center gap-5 items-center">
                    <Button
                      text={"Manual Allocate"}
                      width={"130px"}
                      height={"35px"}
                      fontSize={"12px"}
                      borderRadius={"5px"}
                      color={"#1849A9"}
                      onClick={() => setIsManualAllotedModal(true)}
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}

          {/* Driver details card */}
          {(tripStatus === 2 ||
            tripStatus === 3 ||
            tripStatus === 4 ||
            tripStatus === 5 ||
            tripStatus === 6) &&
          tripDetails.driverId ? (
            <div className=" w-full px-1 flex  my-7 justify-center items-center">
              <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                <div className=" w-full flex justify-between items-center">
                  <div
                    className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                    style={{ fontSize: "16px" }}
                  >
                    Driver & Vehicle Details
                  </div>
                </div>
                <div className=" w-full flex mt-5">
                  <div className=" w-full flex lg:flex-nowrap flex-wrap justify-between  items-center">
                    <div className=" w-full flex justify-center items-center gap-5">
                      <div className=" w-fit hidden lg:block">
                        <img
                          src={driverImage}
                          alt=""
                          style={{
                            height: "130px",
                            width: "130px",
                            maxWidth: "none",
                          }}
                        />
                      </div>
                      <div className=" w-full flex">
                        <div className="flex flex-col gap-4">
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Driver Id
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Driver name
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Mobile number
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex  mt-5 md:mt-0">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Status
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>

                          <div className="  w-full lg:w-40 flex">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Wallet Amount
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.driverID}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.driverName}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              +91 {tripDetails.driver.mobileNo}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              <StatusCard
                                status={
                                  tripDetails.driver.driverVerify
                                    ? "verified"
                                    : "notverified"
                                }
                                isDot
                              />
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              ₹ {tripDetails.driver.walletAvailableBalace}/-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="  flex justify-start items-center gap-5">
                      <div className=" w-fit hidden lg:block">
                        <img
                          src={vehicleImage}
                          alt=""
                          style={{
                            height: "130px",
                            width: "130px",
                            maxWidth: "none",
                          }}
                        />
                      </div>
                      <div className=" w-full flex">
                        <div className=" w-60 flex flex-col gap-4">
                          <div className="  w-full lg:w-40 flex mt-2 md:mt-0">
                            <div
                              className=" w-32 text-text-black"
                              style={{ fontSize: "12px" }}
                            >
                              Vehicle name
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Vehicle number
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Vehicle type
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Permit Exp Date
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                          <div className="  w-full lg:w-40 flex ">
                            <div
                              className=" w-32 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              Insurance Exp Date
                            </div>
                            <div
                              className=" w-3 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              :
                            </div>
                          </div>
                        </div>
                        <div className=" w-full flex flex-col gap-4">
                          <div className=" w-fit lg:w-50 flex mt-2 md:mt-0">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.vehicleName}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.vehicleNo}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.vehicleType.name}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.permitTaxExpiryDate
                                ? tripDetails.driver.permitTaxExpiryDate
                                : "-"}
                            </div>
                          </div>
                          <div className=" w-fit lg:w-50 flex ">
                            <div
                              className="  w-full lg:w-48 text-text-black "
                              style={{ fontSize: "12px" }}
                            >
                              {tripDetails.driver.insuranceExpiryDate
                                ? tripDetails.driver.insuranceExpiryDate
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ) : (
            tripStatus !== 0 &&
            tripStatus !== 1 && (
              <div className=" w-full px-1 flex  my-7 justify-center items-center ">
                <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                  <div className=" w-full h-fit py-4 flex justify-center items-center flex-col gap-5">
                    <div className="text-black text-center font-roboto text-sm leading-5">
                      Please assign driver for this trip !
                    </div>
                    <div className=" w-full flex justify-center gap-5 items-center">
                      <Button
                        text={"Manual Allocate"}
                        width={"130px"}
                        height={"35px"}
                        fontSize={"12px"}
                        borderRadius={"5px"}
                        color={"#1849A9"}
                        onClick={() => setIsManualAllotedModal(true)}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            )
          )}

          {/* Live Tracking Details */}

          {(tripStatus === 3 || tripStatus === 4 || tripStatus === 5) && (
            <div className=" w-full px-1 flex  my-7 justify-center items-center ">
              <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
                <div
                  className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                  style={{ fontSize: "16px" }}
                >
                  Live Tracking
                </div>
                <>
                  <div className=" w-full h-fit py-10 flex justify-center items-center   ">
                    <Popover
                      content={
                        <LiveTrackingPopoverContent
                          beginDate={
                            tripDetails?.startRideDate
                              ? DateUtils.convertDate(
                                  tripDetails?.startRideDate
                                )
                              : "Start Date: Not started"
                          }
                          beginTime={
                            tripDetails?.startRideDate
                              ? DateUtils.convertTime(
                                  tripDetails?.startRideTime
                                )
                              : "Start Time: Not started"
                          }
                        />
                      }
                      placement="top"
                      trigger="hover"
                    >
                      <div
                        className={`w-6 h-6 rounded-full  ${
                          tripStatus === 5
                            ? "bg-[#12B76A]"
                            : tripDetails.startRideStatus === 0
                            ? "bg-[gray]"
                            : "bg-[#12B76A]"
                        }`}
                      ></div>
                    </Popover>
                    <div className=" h-[2px] w-32 bg-gray-300"></div>
                    <Popover
                      content={
                        <LiveTrackingPopoverContent
                          beginDate={
                            tripDetails?.endRideDate
                              ? DateUtils.convertDate(tripDetails?.endRideDate)
                              : "End Date: Not ended"
                          }
                          beginTime={
                            tripDetails?.endRideTime
                              ? DateUtils.convertTime(tripDetails?.endRideTime)
                              : "End Time: Not ended"
                          }
                        />
                      }
                      placement="top"
                      trigger="hover"
                    >
                      <div
                        className={`w-6 h-6 rounded-full  ${
                          tripStatus === 5
                            ? "bg-[#12B76A]"
                            : tripDetails.endRideStatus === 0
                            ? "bg-[gray]"
                            : "bg-[#12B76A]"
                        }`}
                      ></div>
                    </Popover>
                  </div>
                </>
              </Card>
            </div>
          )}

          {/* Driver Logs details */}

          <div className=" w-full px-1 flex md:my-7 justify-center items-center mb-3">
            <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
              <div
                className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                style={{ fontSize: "16px" }}
              >
                Driver Log Details
              </div>
              <Table
                columns={
                  [
                    {
                      id: "driverId",
                      name: <TableHead>Driver ID</TableHead>,
                      cell: (row) => (
                        <TableCell>
                          {row.driver.driverID}
                        </TableCell>
                      ),
                    },
                    {
                      id: "driverName",
                      name: <TableHead>Driver Name</TableHead>,
                      cell: (row) => (
                        <TableCell>
                          {row.driver.driverName}
                        </TableCell>
                      ),
                    },
                    {
                      id: "mobileNumber",
                      name: <TableHead>Mobile Number</TableHead>,
                      cell: (row) => <TableCell>{row.driver.mobileNo}</TableCell>,
                    },
                    {
                      id: "vehicleName",
                      name: <TableHead>Vehicle Name</TableHead>,
                      cell: (row) => <TableCell>{row.driver.vehicleName}</TableCell>,
                    },
                    {
                      id: "vehicleNumber",
                      name: <TableHead>Vehicle Number</TableHead>,
                      cell: (row) => <TableCell>{row.driver.vehicleNo}</TableCell>,
                    },
                    {
                      id: "updatedUnixTym",
                      name: <TableHead>Alloted Time</TableHead>,
                      cell: (row) => <TableCell>{DateUtils.getDateAndTimeFromUnixTimestamp(row.updatedUnixTym)}</TableCell>,
                    },
                  ]
                }
                rows={tripDriverLogs}
                noNeedPagination={true}
              />
            </Card>
          </div>

          <div className=" w-full px-1 flex md:my-7 justify-center items-center mb-3">
            <Card className=" w-full flex flex-col gap-6 pb-4  shadow-sm rounded-md">
              <div
                className=" w-full text-start text-lg mt-1 font- text-gray-800 "
                style={{ fontSize: "16px" }}
              >
                Creater Details
              </div>
              <>
                <div className=" w-full flex-wrap gap-5 h-fit py-4 flex justify-between items-center mt-3">
                  <DateInput
                    label={"Created date"}
                    placeholder={"Select created date"}
                    value={DateUtils.convertDate(
                      tripDetails.createdAt &&
                        tripDetails.createdAt?.split("T")[0]
                    )}
                    setValue={""}
                    disabled={true}
                  />
                  <TimeInput
                    label={"Created time"}
                    placeholder={"Select created time"}
                    value={DateUtils.convertTime(
                      tripDetails.createdAt &&
                        tripDetails.createdAt?.split("T")[1]?.split(".")[0]
                    )}
                    setValue={""}
                    disabled={true}
                  />
                  <Input
                    label={"Created by"}
                    placeholder={"Enter created by"}
                    value={"Super Admin"}
                    setValue={""}
                    disabled={true}
                  />
                </div>
              </>
            </Card>
          </div>
        </div>

        <AssignDriverModal
          isManualAllotedModal={isManualAllotedModal}
          setIsManualAllotedModal={setIsManualAllotedModal}
          selectedRow={tripDetails}
          isTripDetails={true}
        />
      </PageLayout>
    </>
  );
}
