import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import logo from "../../assets/navbar/logo.svg";
import arrow_right from "../../assets/navbar/arrow_right.svg";
import arrow_left from "../../assets/navbar/arrow_left.svg";
import search from "../../assets/navbar/search.svg";
import settings from "../../assets/navbar/settings.svg";
import avatar from "../../assets/navbar/sample_avatar.svg";
import logout from "../../assets/navbar/logout.svg";
import advertisement from "../../assets/navbar/advertisement.svg";

import home from "../../assets/navbar/home.svg";
import trip_management from "../../assets/navbar/trip_management.svg";
import customer_management from "../../assets/navbar/customer_management.svg";
import driver_management from "../../assets/navbar/driver_management.svg";
import wallet_management from "../../assets/navbar/wallet_management.svg";
import fare_management from "../../assets/navbar/fare_management.svg";
import referral_management from "../../assets/navbar/referral_management.svg";
import admin_management from "../../assets/navbar/admin.svg";
import AuthHandler from "../../context-storage/AuthHandler";

export default function Navbar({ isOpen, setIsOpen, children }) {

  const [userType, setUserType] = useState("");

  useEffect(() => {
    setUserType(localStorage.getItem("CC_ADMIN_USERTYPE"));
  }, []);

  const path = useLocation().pathname;

  const LinkActive = (link) => {
    const linkCase = link.toLowerCase();
    const pathCase = path.toLowerCase();
    return pathCase.includes(linkCase);
  };

  const [userDetail, setUserDetail] = useState({})

  const { handleLogout, userData } = useContext(AuthHandler)

  const scrollBarCSS = `
        #scrollableNav::-webkit-scrollbar {
            width: 5px;
        }
        #scrollableNav::-webkit-scrollbar-thumb {
            background: #2E90FA;
            border-radius: 6px;
        }
        #labelSearch {
            position: relative;
        }
        #labelSearch:before {
            content: "";
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 16px;
            background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 16L12.375 12.375M14.3333 7.66667C14.3333 11.3486 11.3486 14.3333 7.66667 14.3333C3.98477 14.3333 1 11.3486 1 7.66667C1 3.98477 3.98477 1 7.66667 1C11.3486 1 14.3333 3.98477 14.3333 7.66667Z' stroke='gray' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") center / contain no-repeat;
        }
    `;

  const navContent = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
      activeLink: "/home"
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
      activeLink: "/trip-management"
    },
    {
      img: customer_management,
      alt: "Customer Management",
      title: "Customer Management",
      link: "/customer-management",
      activeLink: "/customer-management"
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management/drivers",
      activeLink: "/driver-management"
    },
    {
      img: wallet_management,
      alt: "Wallet Management",
      title: "Wallet Management",
      link: "/wallet-management",
      activeLink: "/wallet-management"
    },
    {
      img: fare_management,
      alt: "Fare Management",
      title: "Fare Management",
      link: "/fare-management",
      activeLink: "/fare-management"
    },
    {
      img: referral_management,
      alt: "Redemption Management",
      title: "Redemption Management",
      link: "/redemption-management",
      activeLink: "/redemption-management"
    },
    {
      img: admin_management,
      alt: "Admin Management",
      title: "Admin Management",
      link: "/admin-management",
      activeLink: "/admin-management"
    },
    {
      img: advertisement,
      alt: "Advertisement",
      title: "Advertisement",
      link: "/advertisement",
      activeLink: "/advertisement"
    }
  ];

  const navContent_Admin_Supervisor = [
    {
      img: home,
      alt: "Home",
      title: "Home",
      link: "/home",
      activeLink: "/home"
    },
    {
      img: trip_management,
      alt: "Trip Management",
      title: "Trip Management",
      link: "/trip-management",
      activeLink: "/trip-management"
    },
    {
      img: customer_management,
      alt: "Customer Management",
      title: "Customer Management",
      link: "/customer-management",
      activeLink: "/customer-management"
    },
    {
      img: driver_management,
      alt: "Driver Management",
      title: "Driver Management",
      link: "/driver-management/drivers",
      activeLink: "/driver-management"
    },
    {
      img: wallet_management,
      alt: "Wallet Management",
      title: "Wallet Management",
      link: "/wallet-management",
      activeLink: "/wallet-management"
    },
    {
      img: referral_management,
      alt: "Redemption Management",
      title: "Redemption Management",
      link: "/redemption-management",
      activeLink: "/redemption-management"
    },
  ];

  // const user = {
  //   avatar: avatar,
  //   name: "Olivia Rhye",
  //   email: "olivia@untitledui.com",
  // };

  useEffect(() => {
    path !== "/" ? setIsOpen(true) : setIsOpen(false);
    // eslint-disable-next-line
  }, [path]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
  };

  // const navigate = useNavigate();

  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //   navigate("/");
  //   window.location.reload(); // just a sample logout, remove this reload function
  // };

  useEffect(() => {
    setUserDetail({
      username: localStorage.getItem('CC_ADMIN_USERNAME'),
      email: localStorage.getItem('CC_ADMIN_EMAIL')
    })
  }, [])

  return (
    <>
      <style>{scrollBarCSS}</style>
      <div className="flex  w-full h-screen">
        <div
          className={`flex flex-col   justify-between h-full bg-primary-blue text-white ${!isOpen ? "items-center  w-[60px] lg:w-[80px] " : " w-[250px]"
            }`}
        >
          <div className="w-full">
            <div
              className={`h-[8vh] flex items-center justify-center gap-4 py-8 bg-[#2E90FA] ${!isOpen && "justify-center"
                }`}
            >
              <Link to="/">
                <img src={logo} alt="Logo" className="h-10" />
              </Link>
              {isOpen && <p className="text-3xl font-medium">SISYPHUS</p>}
            </div>
            <div
              id="scrollableNav"
              className={`h-[70vh] flex flex-col gap-[10px] px-2 pt-2 overflow-y-auto ${!isOpen && "items-center"}`}
            >
              <div
                className={`flex flex-col gap-3 w-full ${isOpen ? "items-end" : "items-center"}`}
              >
                <button
                  onClick={handleSetIsOpen}
                  className={`flex items-center w-fit hover:bg-[#175CD3] px-1 py-1 rounded-md`}
                >
                  {isOpen ? (
                    <img src={arrow_left} alt="Expand" />
                  ) : (
                    <img src={arrow_right} alt="Expand" />
                  )}
                </button>
                {/* <button
                  className="flex justify-center w-full"
                  onClick={() => !isOpen && setIsOpen(true)}
                >
                  {isOpen ? (
                    <label
                      id="labelSearch"
                      htmlFor="search"
                      className="text-black"
                    >
                      <input
                        name="search"
                        type="text"
                        className="py-1 pl-10 rounded-md focus:outline-none placeholder:text-placeholder-gray"
                        placeholder={`Search`}
                      />
                    </label>
                  ) : (
                    <img
                      src={search}
                      alt="Search"
                      className="px-2 py-2 rounded-md hover:bg-[#175CD3]"
                    />
                  )}
                </button> */}
              </div>
              {((userType === "superAdminPlus" || userType === "superAdmin") ? navContent : navContent_Admin_Supervisor).map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className={`flex items-center gap-3 px-2 py-2 rounded-md hover:bg-[#175CD3] ${LinkActive(item.activeLink) && "bg-[#175CD3]"}`}
                >
                  <img src={item.img} alt={item.alt} className="h-5" />
                  {isOpen && <p>{item.title}</p>}
                </Link>
              ))}
              <div
                className={`md:hidden flex flex-col h-full justify-end ${!isOpen ? "items-center gap-3" : "gap-2"}`}
              >
                <div className="flex flex-col gap-2">
                  <Link
                    to="/settings"
                    className={`flex items-center gap-4 px-2 py-2 rounded-md hover:bg-[#175CD3] ${path === "/settings" && "bg-[#175CD3]"}`}
                  >
                    <img
                      src={settings}
                      alt="Settings"
                      className="h-5 rounded-md hover:bg-[#175CD3]"
                    />
                    {isOpen && <p>Settings</p>}
                  </Link>
                  <div className="bg-[#175CD3] h-[1.5px] w-full" />
                </div>
                <div className="flex justify-center gap-2">
                  <Link to="">
                    <img src={avatar} alt="Avatar" className={`h-10`} />
                  </Link>
                  {isOpen && (
                    <div className="flex gap-1">
                      <div className="flex flex-col justify-center text-sm">
                        <p className="truncate w-36">{userDetail?.username}</p>
                        <p className="truncate w-36">{userDetail?.email}</p>
                      </div>

                      <button
                        onClick={handleLogout}
                        className="flex justify-start h-fit w-[35px]"
                      >
                        <img src={logout} alt="Logout" className="-mt-2" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`h-[22vh] hidden md:flex flex-col justify-end pb-4 px-2 ${!isOpen ? "items-center gap-3" : "gap-2"}`}
          >
            <div className="flex flex-col gap-2">
              <Link
                to="/settings"
                className={`flex items-center gap-4 px-2 py-2 rounded-md hover:bg-[#175CD3] ${path === "/settings" && "bg-[#175CD3]"}`}
              >
                <img
                  src={settings}
                  alt="Settings"
                  className="h-5 rounded-md hover:bg-[#175CD3]"
                />
                {isOpen && <p>Settings</p>}
              </Link>
              <div className="bg-[#175CD3] h-[1.5px] w-full" />
            </div>
            <div className="flex justify-center gap-2">
              <Link to="">
                <img src={avatar} alt="Avatar" className={`h-10`} />
              </Link>
              {isOpen && (
                <div className="flex gap-1">
                  <div className="flex flex-col justify-center text-sm">
                    <p className="truncate w-36">{userDetail?.username}</p>
                    <p className="truncate w-36">{userDetail?.email}</p>
                  </div>

                  <button
                    onClick={handleLogout}
                    className="flex justify-start h-fit w-[35px]"
                  >
                    <img src={logout} alt="Logout" className="-mt-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${isOpen ? "w-[calc(100%-250px)]" : "w-[calc(100%-60px)]  lg:w-[calc(100%-80px)]"}`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
