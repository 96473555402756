import React, { useState } from "react";
import { Typography, message } from "antd";
import edit from "../assets/edit_icon.svg";
import taxi from "../assets/taxi.svg";
import freezer from "../assets/freezer.svg";
import user from "../assets/user.svg";
import Input from "../../../components/reusable/Input";
import DateInput from "../../../components/reusable/DateInput";
import Button from "../../../components/reusable/Button";
import { updateOccasionalFare } from "../../../services/FareManagementServices";
import { getTripModeId } from "../../utils/utils";
import DateUtils from "../../utils/DateUtils";

const { Title } = Typography;

export default function VehicleFareUpdate({
  id,
  vehicleType,
  vehicalImg,
  numberOfPersons,
  acOrNonAc,
  fromDateFromApi,
  toDateFromApi,
  farePerKm,
  driverFeeAndFarePerHour,
  driverSettlementRental,
  occasionalDriverSettlementRental,
  occasionalFarePerHourRental,
  occasionalFare,
  occasionalDriverFare,
  baseFareKmLocal,
  occasionalBaseFareKmLocal,
  loading,
  getFareManagementDetails,
  activeKey,
  isEditDefault,
  setIsEditDefault,
  isEditOccasional,
  setIsEditOccasional,
  isEditActive,
  setIsEditActive,
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [defaultFare, setDefaultFare] = useState("")
  const [fare, setFare] = useState({ fare: farePerKm, fareError: "" });
  const [driverFare, setDriverFare] = useState({
    fare: driverFeeAndFarePerHour,
    fareError: "",
  });
  const [farePerHour, setFarePerHour] = useState({
    fare: driverFeeAndFarePerHour,
    fareError: "",
  });
  const [driverSettlement, setDriverSettlement] = useState({
    fare: driverSettlementRental,
    fareError: "",
  });
  const [occasionalFarePerKm, setOccasionalFarePerKm] = useState({
    fare: occasionalFare,
    fareError: "",
  });
  const [occasionalDriverFee, setOccasionalDriverFee] = useState({
    fare: occasionalDriverFare,
    fareError: "",
  });
  const [occasionalDriverSettlement, setOccasionalDriverSettlement] = useState({
    fare: occasionalDriverSettlementRental,
    fareError: "",
  });
  const [occasionalFarePerHour, setOccasionalFarePerHour] = useState({
    fare: occasionalFarePerHourRental,
    fareError: "",
  });
  const [baseFareKm, setBaseFareKm] = useState({
    fare: baseFareKmLocal,
    fareError: "",
  });
  const [occasionalBaseFareKm, setOccasionalBaseFareKm] = useState({
    fare: occasionalBaseFareKmLocal,
    fareError: "",
  });
  const [fromDate, setFromDate] = useState(fromDateFromApi);
  const [toDate, setToDate] = useState(toDateFromApi);
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");

  const Valid = (section) => {
    let isValid = true;
    if (section === "default") {
      if (!fare.fare || fare.fare < 0) {
        setFare((prevFare) => ({
          ...prevFare,
          fareError: "Please enter fare/KM",
        }));
        isValid = false;
      } else {
        setFare((prevFare) => ({ ...prevFare, fareError: "" }));
      }
      if (!driverFare.fare || driverFare.fare < 0) {
        setDriverFare((prevFare) => ({
          ...prevFare,
          fareError: "Please enter driver fare",
        }));
        isValid = false;
      } else {
        setDriverFare((prevFare) => ({ ...prevFare, fareError: "" }));
      }
    }
    if (section === "occasional") {
      if (!fromDate && section === "occasional") {
        setFromDateError("Please enter from date");
        isValid = false;
      } else {
        setFromDateError("");
      }
      if (!toDate && section === "occasional") {
        setToDateError("Please enter to date");
        isValid = false;
      } else {
        setToDateError("");
      }
      if (!occasionalFarePerKm.fare || occasionalFarePerKm.fare < 0) {
        setOccasionalFarePerKm((prevFare) => ({
          ...prevFare,
          fareError: "Please enter fare per KM",
        }));
        isValid = false;
      } else {
        setOccasionalFarePerKm((prevFare) => ({ ...prevFare, fareError: "" }));
      }
      if (!occasionalDriverFee.fare || occasionalDriverFee.fare < 0) {
        setOccasionalDriverFee((prevFare) => ({
          ...prevFare,
          fareError: "Please enter driver fare",
        }));
        isValid = false;
      } else {
        setOccasionalDriverFee((prevFare) => ({ ...prevFare, fareError: "" }));
      }
    }
    if (activeKey === "rental") {
      if (section === "occasional") {
        if (!occasionalFarePerHour.fare || occasionalFarePerHour.fare < 0) {
          setOccasionalFarePerHour((prevFare) => ({
            ...prevFare,
            fareError: "Please enter fare per hour",
          }));
          isValid = false;
        } else {
          setOccasionalFarePerHour((prevFare) => ({
            ...prevFare,
            fareError: "",
          }));
        }
        if (
          !occasionalDriverSettlement.fare ||
          occasionalDriverSettlement.fare < 0
        ) {
          setOccasionalDriverSettlement((prevFare) => ({
            ...prevFare,
            fareError: "Please enter driver settlement",
          }));
          isValid = false;
        } else {
          setOccasionalDriverSettlement((prevFare) => ({
            ...prevFare,
            fareError: "",
          }));
        }
      }
      if (section === "default") {
        if (!farePerHour.fare || farePerHour.fare < 0) {
          setFarePerHour((prevFare) => ({
            ...prevFare,
            fareError: "Please enter fare per hour",
          }));
          isValid = false;
        } else {
          setFarePerHour((prevFare) => ({ ...prevFare, fareError: "" }));
        }
        if (!driverSettlement.fare || driverSettlement.fare < 0) {
          setDriverSettlement((prevFare) => ({
            ...prevFare,
            fareError: "Please enter driver settlement",
          }));
          isValid = false;
        } else {
          setDriverSettlement((prevFare) => ({ ...prevFare, fareError: "" }));
        }
      }
    }
    return isValid;
  };

  function handleEditDefault() {
    if (isEditActive) return;
    setIsEditActive(true);
    const updatedIsEdit = {
      sedan: vehicleType.toLowerCase() === "sedan",
      suv: vehicleType.toLowerCase() === "suv",
      suvPlus: vehicleType.toLowerCase() === "suv+",
      muv: vehicleType.toLowerCase() === "muv",
      tempo: vehicleType.toLowerCase() === "tempo",
    };
    setIsEditDefault(updatedIsEdit);
  }
  function handleEditOccasional() {
    if (isEditActive) return;
    setIsEditActive(true);
    const updatedIsEdit = {
      sedan: vehicleType.toLowerCase() === "sedan",
      suv: vehicleType.toLowerCase() === "suv",
      suvPlus: vehicleType.toLowerCase() === "suv+",
      muv: vehicleType.toLowerCase() === "muv",
      tempo: vehicleType.toLowerCase() === "tempo",
    };
    setIsEditOccasional(updatedIsEdit);
  }

  async function handleUpdate(section) {
    if (Valid(section)) {
      setButtonLoading(true);
      const updatedData = {
        id: parseInt(id),
        fromDate: fromDate,
        toDate: toDate,
        fareType: section === "default" ? 0 : 1,
        farePerKm: fare.fare,
        driverFeeAndFarePerHour:
          activeKey === "rental" || activeKey === "local-drop"
            ? farePerHour.fare
            : driverFare.fare,
        driverSettlement: driverSettlement.fare,
        occasionalDriverSettlement: occasionalDriverSettlement.fare,
        occasionalFare: occasionalFarePerKm.fare,
        occasionalDriverFare:
          activeKey === "rental"
            ? occasionalFarePerHour.fare
            : occasionalDriverFee.fare,
        basefareKm: baseFareKm.fare,
        occasionalBaseFareKm: occasionalBaseFareKm.fare,
      };
      try {
        const response = await updateOccasionalFare(updatedData);
        if (response.code === 200) {
          message.success("Fare updated succesfully");
          getFareManagementDetails(getTripModeId(activeKey));
          setIsEditDefault({
            sedan: false,
            suv: false,
            suvPlus: false,
            muv: false,
            tempo: false,
          });
          setIsEditOccasional({
            sedan: false,
            suv: false,
            suvPlus: false,
            muv: false,
            tempo: false,
          });
          setButtonLoading(false);
          setIsEditActive(false);
        }
      } catch (error) {
        setButtonLoading(false);
        message.error(
          error.response.data.error ||
            error.message ||
            error.response.data.message
        );
      }
    }
  }

  function handleCancel() {
    setFare({ fare: farePerKm || "", fareError: "" });
    setDriverFare({ fare: driverFeeAndFarePerHour || "", fareError: "" });
    setDriverSettlement({ fare: driverSettlementRental || "", fareError: "" });
    setFarePerHour({ fare: driverFeeAndFarePerHour || "", fareError: "" });
    setOccasionalFarePerKm({ fare: occasionalFare || "", fareError: "" });
    setOccasionalDriverFee({ fare: occasionalDriverFare || "", fareError: "" });
    setOccasionalDriverSettlement({
      fare: occasionalDriverSettlementRental || "",
      fareError: "",
    });
    setOccasionalFarePerHour({
      fare: occasionalFarePerHourRental || "",
      fareError: "",
    });
    setFromDate(DateUtils.convertDate(fromDateFromApi));
    setToDate(DateUtils.convertDate(toDateFromApi));
    setFromDateError("");
    setToDateError("");
    const updatedIsEdit = {
      sedan: false,
      suv: false,
      suvPlus: false,
      muv: false,
      tempo: false,
    };
    setIsEditDefault(updatedIsEdit);
    setIsEditOccasional(updatedIsEdit);
    setIsEditActive(false);
  }

  return (
    <>
      <div className="flex md:flex-row flex-col justify-between w-full border rounded-md border-gray-200">
        {/* Car Card Section */}
        <section className="w-full flex flex-col items-center justify-between gap-5 p-3 border-b md:border-b-0">
          <Title
            level={2}
            className="text-center font-semibold"
            style={{ marginBottom: "0px", fontSize: "24px" }}
          >
            {vehicleType}
          </Title>
          <img
            src={vehicalImg}
            alt={vehicleType}
            className="w-fit max-h-[100px]"
            style={{ margin: "auto" }}
          />
          <div className="flex flex-wrap gap-3 md:flex-nowrap items-center justify-around md:justify-between my-2">
            <div
              className="bg-[#000] text-[#fff] w-[80px] flex items-center justify-center rounded-md shadow-md font-medium text-xs pr-1"
              style={{ height: "30px" }}
            >
              <div className="pl-1 pr-2">
                <img src={taxi} alt="icon" className="w-5 h-5" />
              </div>
              {vehicleType}
            </div>
            <div
              className="bg-[#000] text-[#fff] w-[80px] flex items-center justify-center rounded-md shadow-md font-medium text-xs"
              style={{ height: "30px" }}
            >
              <div className="pl-1 pr-2">
                <img src={user} alt="icon" className="w-5 h-5" />
              </div>
              {numberOfPersons}
            </div>
            <div
              className="bg-[#000] text-[#fff] w-[80px] flex items-center justify-center rounded-md shadow-md font-medium text-xs"
              style={{ height: "30px" }}
            >
              <div className="pl-1 pr-2">
                <img src={freezer} alt="icon" className="w-5 h-5" />
              </div>
              {acOrNonAc}
            </div>
          </div>
        </section>
        {/* Default fare section */}
        <section className="w-full flex flex-col items-center gap-5 p-3 border-l border-b md:border-b-0">
          <div className="flex justify-between w-full px-4">
            <h3 className="text-center font-semibold text-lg">Default Fare</h3>
            <button
              onClick={handleEditDefault}
              className={`${isEditActive ? "cursor-not-allowed" : ""}`}
            >
              <img src={edit} alt="edit" />
            </button>
          </div>
          <div className="flex flex-col gap-4 mt-4 w-full justify-center items-center">
            {activeKey === "rental" && (
              <Input
                label={"Fare Per Hour"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={farePerHour.fare}
                onChange={(e) =>
                  setFarePerHour((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={farePerHour.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            {activeKey === "local-drop" && (
              <Input
                label={"Base Fare Rs"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={farePerHour.fare}
                onChange={(e) =>
                  setFarePerHour((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={farePerHour.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            <Input
              label={
                activeKey === "rental"
                  ? "Fare Per KM"
                  : activeKey === "local-drop"
                  ? "Extra KM Fare"
                  : "Fare/KM"
              }
              type={"number"}
              placeholder={"-"}
              mandate
              value={fare.fare}
              onChange={(e) =>
                setFare((prev) => ({ ...prev, fare: parseInt(e.target.value) }))
              }
              error={fare.fareError}
              disabled={
                !isEditDefault[
                  vehicleType.toLowerCase() === "suv+"
                    ? "suvPlus"
                    : vehicleType.toLowerCase()
                ]
              }
            />
            {activeKey === "rental" ? (
              <Input
                label={"Driver Settlement"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={driverSettlement.fare}
                onChange={(e) =>
                  setDriverSettlement((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={driverSettlement.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            ) : activeKey === "local-drop" ? (
              <Input
                label={"Base Fare KM"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={baseFareKm.fare}
                onChange={(e) =>
                  setBaseFareKm((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={baseFareKm.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            ) : (
              <Input
                label={"Driver fare"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={driverFare.fare}
                onChange={(e) =>
                  setDriverFare((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={driverFare.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            <div className="w-full pt-4">
              {isEditDefault[
                vehicleType.toLowerCase() === "suv+"
                  ? "suvPlus"
                  : vehicleType.toLowerCase()
              ] && (
                <div className="flex flex-wrap p-6 pt-0 gap-3 items-center justify-center">
                  <Button
                    text={"Cancel"}
                    bgColor={"#C4320A"}
                    textColor={"#FFFFFF"}
                    onClick={handleCancel}
                    width={"150px"}
                  />
                  <Button
                    loading={buttonLoading}
                    text={"Update"}
                    bgColor={"#1849A9"}
                    textColor={"#FFFFFF"}
                    onClick={() => handleUpdate("default")}
                    width={"150px"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        {/* Occasional fare section */}
        <section className="w-full flex flex-col items-center justify-between gap-5 p-3 border-l">
          <div className="flex justify-between w-full px-4">
            <h3 className="text-center font-semibold text-lg">
              Occasional Fare
            </h3>
            <button
              onClick={handleEditOccasional}
              className={`${isEditActive ? "cursor-not-allowed" : ""}`}
            >
              <img src={edit} alt="edit" />
            </button>
          </div>
          <div className="flex flex-col gap-4 mt-4 w-full justify-center items-center">
            {activeKey === "rental" && (
              <Input
                label={"Occational Fare Per Hour"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalFarePerHour.fare}
                onChange={(e) =>
                  setOccasionalFarePerHour((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalFarePerHour.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}

            {activeKey === "local-drop" && (
              <Input
                label={"Occational Base Fare Rs"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalFarePerHour.fare}
                onChange={(e) =>
                  setOccasionalFarePerHour((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalFarePerHour.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            {activeKey === "local-drop" ? (
              <Input
                label={"Occasional Base Fare KM"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalBaseFareKm.fare}
                onChange={(e) =>
                  setOccasionalBaseFareKm((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalBaseFareKm.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            ) : (
              <Input
                label={"Occasional Fare/km"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalFarePerKm.fare}
                onChange={(e) =>
                  setOccasionalFarePerKm((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalFarePerKm.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            {activeKey === "rental" ? (
              <Input
                label={"Occasional Driver settlement"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalDriverSettlement.fare}
                onChange={(e) =>
                  setOccasionalDriverSettlement((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalDriverSettlement.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            ) : activeKey === "local-drop" ? (
              <Input
                label={"Occasional Extra KM Fare"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalFarePerKm.fare}
                onChange={(e) =>
                  setOccasionalFarePerKm((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalFarePerKm.fareError}
                disabled={
                  !isEditDefault[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            ) : (
              <Input
                label={"Occasional Driver fare"}
                type={"number"}
                placeholder={"-"}
                mandate
                value={occasionalDriverFee.fare}
                onChange={(e) =>
                  setOccasionalDriverFee((prev) => ({
                    ...prev,
                    fare: parseInt(e.target.value),
                  }))
                }
                error={occasionalDriverFee.fareError}
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
              />
            )}
            <div className="flex flex-col lg:flex-row pt-0 gap-3 md:gap-9 mt-1 items-center justify-center w-full md:w-[130px]">
              <DateInput
                label={"From date"}
                placeholder={"-"}
                mandate
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
                value={fromDate}
                setValue={setFromDate}
                error={fromDateError}
                disableMinWidth
              />
              <DateInput
                label={"To date"}
                placeholder={"-"}
                mandate
                disabled={
                  !isEditOccasional[
                    vehicleType.toLowerCase() === "suv+"
                      ? "suvPlus"
                      : vehicleType.toLowerCase()
                  ]
                }
                value={toDate}
                setValue={setToDate}
                error={toDateError}
                disableMinWidth
              />
            </div>
            <div className="w-full pt-4">
              {isEditOccasional[
                vehicleType.toLowerCase() === "suv+"
                  ? "suvPlus"
                  : vehicleType.toLowerCase()
              ] && (
                <div className="flex flex-wrap p-6 pt-0 gap-3 items-center justify-center">
                  <Button
                    text={"Cancel"}
                    bgColor={"#C4320A"}
                    textColor={"#FFFFFF"}
                    onClick={handleCancel}
                    width={"150px"}
                  />
                  <Button
                    loading={buttonLoading}
                    text={"Update"}
                    bgColor={"#1849A9"}
                    textColor={"#FFFFFF"}
                    onClick={() => handleUpdate("occasional")}
                    width={"150px"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
