import Button from "../../../components/reusable/Button";

function TicketCard({
  ticketId,
  employeeId,
  employeeName,
  reason,
  description,
  onClick,
}) {
  return (
    <>
      <div className=" hidden lg:flex flex-col border rounded-md h-fit">
        <div className="flex justify-between items-center px-6 py-3 font-semibold">
          <p className="text-primary-blue">{ticketId}</p>
          <p>{employeeId}</p>
          <p>{employeeName}</p>
          <p>{reason}</p>
        </div>
        <hr />
        <div className="flex justify-between items-center px-3 py-3">
          <p className="font-semibold">{description}</p>
          <Button
            text={"View Details"}
            width={140}
            height={35}
            onClick={onClick}
          />
        </div>
      </div>
      <div className="flex flex-col py-2 pb-3  px-2 lg:hidden  border rounded-md h-fit">
        <div className=" flex justify-between px-1 pt-2 items-center w-full ">
          <p className="text-primary-blue  ">{ticketId}</p>
          <p>{employeeId}</p>
        </div>
        <div className="flex justify-between px-1 py-2 items-center w-full ">
          <p>{employeeName}</p>
          <p>{reason}</p>
        </div>

        <hr />
        <div className="flex justify-between items-center px-3 py-3">
          <p className="font-semibold">{description}</p>
        </div>
        <div className=" w-full  flex justify-end  items-center">
          <Button
            text={"View Details"}
            width={140}
            height={35}
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
}

export default function TicketDetails({ data }) {
  const totalHeight = data.length * 60; // assuming each ticket card is roughly 100px tall

  return (
    <div className="shadow-sm  w-fit flex px-4 flex-auto flex-col gap-8 pr-6 h-[370px] border rounded-md relative max-h-[600px] overflow-hidden">
      <p className="text-lg font-semibold pb-2  z-50  pt-3   bg-white   w-full ">
        Ticket Details
      </p>
      <div className="scrolling-tickets ">
        {data.concat(data).map((ticket, index) => (
          <TicketCard
            key={index}
            ticketId={ticket.ticketId}
            employeeId={ticket.employeeId}
            employeeName={ticket.employeeName}
            reason={ticket.reason}
            description={ticket.description}
            onClick={ticket.onClick}
          />
        ))}
      </div>
      <style jsx>{`
        .scrolling-tickets {
          animation: scroll-tickets 10s linear infinite;
          transform: translateY(0);
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        @keyframes scroll-tickets {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-${totalHeight}px);
          }
        }
        .scrolling-tickets:hover {
          animation-play-state: paused;
        }
      `}</style>
    </div>
  );
}
