import axios from "axios";


export const getStates = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getStates`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const getDistricts = async (stateId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDistricts/?id=${stateId}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const getVehicleNumbers = async (vehicleSearch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getVehicleDropDown?searchQuery=${vehicleSearch}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}


export const getDriversIdDropdown = async (vehicleTypeId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDriversIdDropDown?vehicleTypeID=${vehicleTypeId}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}

export const getDriverTrips = async (driverId, page, rowsPerPage) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/driver/getDriverRideDetails?driverId=${driverId}&currentPage=${page}&pageSize=${rowsPerPage}`, {
            headers: {
                "x-access-token": localStorage.getItem("CC_ADMIN_TOKEN"),
                "email": localStorage.getItem("CC_ADMIN_EMAIL"),
                "usertype": localStorage.getItem("CC_ADMIN_USERTYPE")
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            window.location.href = "/login";
        } else {
            console.error("Error:", error);
        }
        return error.response && error.response.data;
    }
}