import { Card, Divider, Modal, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import Button from '../../../components/reusable/Button';
import addTrip from '../asserts/add-trip.png';
import Input from '../../../components/reusable/Input';
import MobileNumberInput from '../../../components/reusable/MobileNumberInput';
import { getCustomerByMobileNumber } from '../../../services/CustomerManagementServices';
import { initiateRedemption } from '../../../services/RedeemManagementServices';
import { CloseCircleOutlined } from '@ant-design/icons';
import AntButton from "antd/lib/button"

export default function InitiateRedemptionModal({
    title,
    showModal,
    setShowModal,
    getAllRedeemData
}) {
    const [customerSearch, setCustomerSearch] = useState('')
    const [customerData, setCustomerData] = useState({
        data: {},
        state: false,
        isAvailableBalance: false
    })
    const [redeemInputs, setRedeemInputs] = useState({
        redeemAmount: '',
        gPayProfileName: '',
        gPayNumber: ''
    })
    const [errors, setErrors] = useState({
        redeemAmountError: '',
        gPayProfileNameError: '',
        gPayNumberError: ''
    })

    const handleSearch = async () => {
        try {
            const response = await getCustomerByMobileNumber(customerSearch)
            if (response) {
                setCustomerData({
                    state: response?.data?.sum?.totalAvailableRewardPoints > 50.00,
                    data: response.data,
                    isAvailableBalance: true,
                })
                setCustomerSearch('')
            }
        }
        catch (error) {
            message.error(error.response.data.message || error.message)
        }
    }

    const isValid = () => {
        let isValid = true;

        if (!redeemInputs?.redeemAmount) {
            setErrors(prev => ({
                ...prev,
                redeemAmountError: 'Redeem amount is required'
            }))
            isValid = false
        } else {
            setErrors(prev => ({
                ...prev,
                redeemAmountError: ''
            }))
        }

        if (!redeemInputs.gPayProfileName) {
            setErrors(prev => ({
                ...prev,
                gPayProfileNameError: 'G Pay Profile Name is required'
            }))
            isValid = false
        } else {
            setErrors(prev => ({
                ...prev,
                gPayProfileNameError: ''
            }))
        }

        if (!redeemInputs.gPayNumber) {
            setErrors(prev => ({
                ...prev,
                gPayNumberError: 'G Pay Number is required'
            }))
            isValid = false
        } else {
            setErrors(prev => ({
                ...prev,
                gPayNumberError: ''
            }))
        }

        return isValid;
    }


    const handleInitiateRedemption = async () => {
        if (isValid()) {

            //Checking if the amount the greater than 50
            if (+customerData?.data?.sum?.totalAvailableRewardPoints?.toFixed(2) <= 50.00) {
                message.error('Available redeem amount should be greater than ₹50 to initiate redemption!')
                return
            }
            else {
                if (+redeemInputs.redeemAmount <= +customerData?.data?.sum?.totalAvailableRewardPoints) {
                    try {
                        const payload = {
                            id: +customerData?.data?.customer?.id,
                            amount: +redeemInputs.redeemAmount,
                            gPayProfileName: redeemInputs.gPayProfileName,
                            gPayNumber: redeemInputs.gPayNumber
                        }
                        const response = await initiateRedemption(payload);
                        if (response) {
                            getAllRedeemData(0, 0, 25)
                            message.success(response.message)
                            setShowModal(false)
                            setCustomerData({
                                data: {},
                                state: false,
                                isAvailableBalance: false
                            })
                            setCustomerSearch('')
                            setRedeemInputs({
                                redeemAmount: '',
                                gPayProfileName: '',
                                gPayNumber: ''
                            })
                            setErrors({
                                redeemAmountError: '',
                                gPayProfileNameError: '',
                                gPayNumberError: ''
                            })
                        }
                    }
                    catch (err) {
                        message.error(err.message);
                    }
                }
                else {
                    message.error('Redeem amount should be less than or equal to available redeem amount!')
                    return
                }
            }
        }
    }


    return (
        <>
            <Modal
                title={
                    <>
                        <div className="flex flex-col gap-2">
                            <img src={addTrip} width={40} alt="" />
                            <h1 className="text-lg" style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</h1>
                        </div>
                    </>
                }
                visible={showModal}
                width={"1000px"}
                footer={null}
                onCancel={() => {
                    setShowModal(false)
                    setCustomerData({
                        data: {},
                        state: false,
                        isAvailableBalance: false
                    })
                    setCustomerSearch('')
                    setRedeemInputs({
                        redeemAmount: '',
                        gPayProfileName: '',
                        gPayNumber: ''
                    })
                    setErrors({
                        redeemAmountError: '',
                        gPayProfileNameError: '',
                        gPayNumberError: ''
                    })
                }}
            >

                <div className='flex flex-col gap-2 my-10'>
                    <div className='flex flex-col justify-end gap-10'>
                        <div className='flex items-end gap-5 justify-end'>
                            <Input
                                placeholder={'Search Customer by mobile number'}
                                value={customerSearch}
                                onChange={(e) => setCustomerSearch(e.target.value)}
                            />
                            <Button
                                text={'Search'}
                                width={'70px'}
                                onClick={handleSearch}
                            />

                        </div>
                        {
                            customerData?.isAvailableBalance && <div className='self-end text-gray-500 font-bold text-[18px]'>Available Balance: <span className={`${customerData?.data?.sum?.totalAvailableRewardPoints?.toFixed(2) < 50.00 ? 'text-red-400' : 'text-green-400'}`}>₹ {customerData?.data?.sum?.totalAvailableRewardPoints?.toFixed(2)}</span></div>
                        }
                    </div>
                    <Divider />
                    {/* <div className='flex justify-end '>
                        <Tooltip title="clear">
                            <AntButton shape="circle" icon={<CloseCircleOutlined />} />
                        </Tooltip>
                    </div> */}
                    <div className="w-full px-5 gap-5 h-fit py-5 flex flex-wrap justify-between lg:grid lg:grid-cols-3">

                        <Input
                            label={"Redeem Amount"}
                            placeholder={"Enter Redeem Amount"}
                            value={redeemInputs?.redeemAmount}
                            onChange={(e) => setRedeemInputs(prev => ({ ...prev, redeemAmount: e.target.value }))}
                            error={errors.redeemAmountError}
                            disabled={!customerData.state}
                            mandate
                            amount
                        />

                        <Input
                            label={"Google Pay Profile Name"}
                            placeholder={"Enter Google Pay Profile Name"}
                            value={redeemInputs?.gPayProfileName}
                            onChange={(e) => setRedeemInputs(prev => ({ ...prev, gPayProfileName: e.target.value }))}
                            error={errors.gPayProfileNameError}
                            disabled={!customerData.state}
                            mandate
                        />
                        <MobileNumberInput
                            label={"Google Pay Number"}
                            placeholder={"Enter Google Pay Number"}
                            disabled={!customerData.state}
                            value={redeemInputs?.gPayNumber}
                            setValue={(item) => setRedeemInputs(prev => ({ ...prev, gPayNumber: item }))}
                            error={errors.gPayNumberError}
                            mandate
                        />

                        {/* <div className="w-[300px]"></div> */}
                    </div>
                </div>

                {
                    customerData.state && (
                        <div className="flex justify-center mb-10">
                            <Button
                                text={"Submit"}
                                textColor={"#fff"}
                                width={"100px"}
                                height={"32px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    handleInitiateRedemption()
                                }}
                            />
                        </div>
                    )

                }

            </Modal>
        </>
    )
}
