import Button from "../reusable/Button";
import Navigator from "./Navigator";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.png";

export default function PageLayout({
    title,
    description,
    buttonTitle,
    buttonWidth,
    children,
    BreadcrumbsUrl,
    setAddTripModal,
    paddingRight,
    debiteCreditButton,
    setAddTripModal1,
    setAddTripModal2,
    buttonIcon
}) {

    return (
        <Navigator
        // BreadcrumbsUrl={BreadcrumbsUrl}
        >
            <div className="flex flex-col w-full h-full pt-3 relative">
                <div className="flex flex-col md:flex-row justify-between w-full md:px-5 py-3">
                    {title && (
                        <div>
                            <h3 className="pt-3 text-[20px] md:text-[25px] whitespace-nowrap" style={{ fontFamily: 'Rubik Dirt' }}>{title}</h3>
                            <h3 className="pt-1 text-placeholder-gray whitespace-nowrap hidden md:block">{description}</h3>
                        </div>
                    )}
                    <div>
                        {buttonTitle && (
                            <div className="  mr-0 lg:mr-1 lg:mt-0 mt-3">
                                <Button
                                    onClick={() => {
                                        setAddTripModal(true);

                                    }}
                                    text={buttonTitle}
                                    width={buttonWidth}
                                    icon={buttonIcon || plus}
                                    paddingRight={paddingRight}
                                />
                            </div>

                        )}
                        {debiteCreditButton && (
                            // Debit Money
                            // Credit Money
                            <div className="flex  gap-4">
                                <div className="  mr-0 lg:mr-1 lg:mt-0 mt-3">
                                    <Button
                                        onClick={() => {
                                            setAddTripModal1(true);

                                        }}
                                        bgColor={"#B42318"}
                                        text={"Debit Money"}
                                        width={buttonWidth}
                                        icon={minus}
                                        paddingRight={paddingRight}
                                    />
                                </div>   <div className="  mr-0 lg:mr-1 lg:mt-0 mt-3">
                                    <Button
                                        onClick={() => {
                                            setAddTripModal2(true);

                                        }}
                                        bgColor={"#00904D"}
                                        text={"Credit Money"}
                                        width={buttonWidth}
                                        icon={plus}
                                        paddingRight={paddingRight}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div >{children}</div>
            </div>
        </Navigator>
    );
}