import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../reusable/Table/Table";
import TableHead from "../../../reusable/Table/TableHead";
import TableCell from "../../../reusable/Table/TableCell";
import DateUtils from "../../../../services/DateUtils";
import StatusCard from "../../../reusable/Table/TripStatus";


export default function TripDetailsTable({ rows, rowsPerPage, page, rowCount, handleChangePage, handleChangeRowsPerPage }) {

    const getBookingMode = (id) => {
        if (id === 1) {
            return "Application";
        } else if (id === 2) {
            return "Website";
        } else if (id === 3) {
            return "Manual";
        }
        return "";
    };

    const getEndFrom = (id) => {
        if (id === 1) {
            return "Manual";
        } else if (id === 2) {
            return "Application";
        }
        return "";
    };

    const getTripType = (id) => {
        if (id === 1) {
            return "One Way";
        } else if (id === 2) {
            return "Round Way";
        } else if (id === 3) {
            return "Rental";
        } else if (id === 4) {
            return "Local Drop";
        }
        return "";
    };

    const getTripStatus = (id) => {
        if (id === 0) {
            return "New Booking";
        } else if (id === 1) {
            return "Accepted";
        } else if (id === 2) {
            return "Proceeded";
        } else if (id === 3) {
            return "appallot";
        } else if (id === 4) {
            return "manualallot";
        } else if (id === 5) {
            return "Completed";
        } else if (id === 6) {
            return "Cancelled";
        } else if (id === 7) {
            return "Faked";
        }
        return "";
    };
    const columns = [
        {
            id: "id",
            name: <TableHead>Trip ID</TableHead>,
            cell: (row) => (
                <TableCell>
                    <Link
                        className=" text-primary-blue"
                        to={`/trip-management/${row.rideId}/${row.tripMode?.tripName}`}
                        state={{ id: row?.id, tripMode: { id: row?.tripType } }}
                    >
                        {row.rideId}
                    </Link>
                </TableCell>
            ),
        },
        {
            id: "tripDate",
            name: <TableHead>Trip Date</TableHead>,
            cell: (row) => <TableCell>{DateUtils.convertDate(row?.dateOfRide)}</TableCell>,
        },
        {
            id: "tripType",
            name: <TableHead width="180px">Trip Type</TableHead>,
            cell: (row) => <TableCell width="180px">{getTripType(row?.tripType)}</TableCell>,
        },

        {
            id: "bookingMode",
            name: <TableHead width="180px">Booking Mode</TableHead>,
            cell: (row) => <TableCell width="180px">
                <StatusCard status={getBookingMode(row.bookingModeId)} />
            </TableCell>,
        },
        {
            id: "endFrom",
            name: <TableHead>Completion Mode</TableHead>,
            cell: (row) => <TableCell>
                {
                    (
                        (row.tripStatus === 3) || (row.tripStatus === 4)
                    )
                        ? <StatusCard isDot status={'ongoing'} />
                        : <StatusCard status={getEndFrom(row.endFrom)} />
                }

            </TableCell>,
        },
        {
            id: "finalFare",
            name: <TableHead>Final Fare</TableHead>,
            cell: (row) => <TableCell>
                {
                    (
                        (row.tripStatus === 3) || (row.tripStatus === 4)
                    )
                        ? <StatusCard isDot status={'ongoing'} />
                        : `₹ ${row.totalEndFare?.toLocaleString('hi')}`
                }
            </TableCell>,
        },
        {
            id: "status",
            name: <TableHead>Trip Current Status</TableHead>,
            cell: (row) => <TableCell>
                <StatusCard status={getTripStatus(row.tripStatus)} />
            </TableCell>,
        },
    ];

    return (
        <div className="w-full removeScrollBar pb-5">
            <Table
                columns={columns}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                rowCount={rowCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                isRowsPerPage={true}
            />
        </div>
    );
}
