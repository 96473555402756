

export const isNotEmpty = (value) => {
    return value !== "";
}

export const isNotValidDate = (value) => {
    const enteredDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0)
    
    if(enteredDate < currentDate) {
        return false;
    } else {
        return true;
    }
}

export const isNotValidTime = (inputTime, sDate) => {
    console.log(inputTime.value, sDate);
    var currentDate = new Date(); // Current date
    let selectedDate = new Date(sDate);

    if (selectedDate.getDate() === currentDate.getDate() && 
        selectedDate.getMonth() === currentDate.getMonth() && 
        selectedDate.getFullYear() === currentDate.getFullYear()) {

        var timeParts = inputTime.value.split(":");
        var hours = parseInt(timeParts[0]);
        var minutes = parseInt(timeParts[1]);
        
        var currentHours = currentDate.getHours();
        var currentMinutes = currentDate.getMinutes();
        
        
        if (hours < currentHours || (hours === currentHours && minutes <= currentMinutes)) {
            return false; 
        }
    }
    return true;
}